import "./ClaimDistToolTip.css";
import investAbi from "../../contract/JNSInvest.json";
import { useSelector } from "react-redux";
import { etherToFixed, InvestmentPoolAddressPls } from "../../const/const";
import { useEffect, useState } from "react";
import { pulsechain } from "viem/chains";
import { useReadContracts } from "wagmi";

const JNSInvestPoolTooltip = (props) => {
    const { children, plsValue } = props;
    const pls_to_usd = useSelector((state) => state.price.pls_to_usd);

    const [buyBurn, setBuyBurn] = useState(0);
    const [buyBurnUsd, setBuyBurnUsd] = useState(0);
    const [bunsSwap, setBunsSwap] = useState(0);
    const [bunsSwapUsd, setBunsSwapUsd] = useState(0);

    const [PLS, setPLS] = useState(0);
    const [PLSUsd, setPLSUsd] = useState(0);
    const [user, setUser] = useState(0);
    const [userUsd, setUserusd] = useState(0);

    const contract = {
        address: InvestmentPoolAddressPls,
        abi: investAbi.abi,
        chainId: pulsechain.id,
    };

    const { data: treasuryData } = useReadContracts({
        contracts: [
            {
                ...contract,
                functionName: "buyBurnShareBPS",
            },
            {
                ...contract,
                functionName: "incentiveFee",
            },
            {
                ...contract,
                functionName: "capPerDistribution",
            },
        ],
        watch: true,
    });

    useEffect(() => {
        if (treasuryData && treasuryData[0].status === "success") {
            const buyBurnShareBPS = treasuryData[0].result;
            const incentiveFee = treasuryData[1].result;
            const distributionCap = treasuryData[2].result;
            const distrbutionValue = plsValue > distributionCap ? distributionCap : plsValue;
            const userIncentive = (etherToFixed(distrbutionValue) * incentiveFee) / 10000;
            const distributionAfterIncentive = etherToFixed(distrbutionValue) - userIncentive;
            const buyBurnValue = (distributionAfterIncentive * buyBurnShareBPS) / 10000;
            const bunsValue = distributionAfterIncentive - buyBurnValue;
            setBuyBurn(
                buyBurnValue.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
            );
            setBuyBurnUsd((buyBurnValue * pls_to_usd).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
            setBunsSwap(
                bunsValue.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
            );
            setBunsSwapUsd(
                (bunsValue * pls_to_usd).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
            );
            setUser(
                userIncentive.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
            );
            setUserusd(
                (userIncentive * pls_to_usd).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
            );
        }
    }, [plsValue, treasuryData]);
    return (
        <div className="claimdisttooltip investtool">
            {children}
            <div className="claimdisttooltip_content">
                <header className="modal_header">
                    <div className="header_top">Send PLS to JNS Buy & Burn</div>
                </header>
                <main className="modal_content">
                    <div className="content_container">
                        <div className="counter_box">
                            <div className="token_counter">
                                <div className="token_counter_name">
                                    <span>To JNS Buy & Burn</span>
                                </div>
                                <div className="token_value">{buyBurn} PLS</div>
                            </div>
                            <div className="dollar_counter">≈${buyBurnUsd}</div>
                        </div>
                        <div className="counter_box">
                            <div className="token_counter">
                                <div className="token_counter_name">
                                    <span>To Buy BUNS</span>
                                </div>
                                <div className="token_value">{bunsSwap} PLS</div>
                            </div>
                            <div className="dollar_counter">≈${bunsSwapUsd}</div>
                        </div>
                        <div className="counter_box">
                            <div className="token_counter">
                                <div className="token_counter_name">
                                    <span>User Reward</span>
                                </div>
                                <div className="token_value">{user} PLS</div>
                            </div>
                            <div className="dollar_counter">≈${userUsd}</div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default JNSInvestPoolTooltip;
