import React, { useEffect, useState } from "react";
import RewardContentStyleWrapper from "./RewardPoolContent.style";
import ProgressBar from "@ramonak/react-progress-bar";
import RewardPoolPayout from "../../../common/rewardspoolpayout/RewardPoolPayout";
import tooltipText from "../../../assets/helius/tooltipText.json";
import heliosContract from "../../../contract/helios.json";
import treasuryContract from "../../../contract/treasury.json";
import { Link } from "react-router-dom";
import { calculatePrice, calculateTotalPrice } from "../../../const/const";

import {
  useAccount,
  useReadContract,
  useReadContracts,
  useWriteContract,
  useWaitForTransactionReceipt,
  useBalance,
  useSwitchChain,
  useChainId,
} from "wagmi";
import { useSelector } from "react-redux";
import {
  TreasuryAddress,
  HeliosAddress,
  etherToFixed,
} from "../../../const/const";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "../../../common/tooltip";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import DistTinethTooltip from "../../modal/DistTinethTooltip";
import DistPayoutTooltip from "../../modal/DistPayoutTooltip";
import { ethers } from "ethers";
import { mainnet } from "wagmi/chains";
import { mainnet2 } from "../../../const/chains";

const RewardPoolContent = () => {
  const { address, isConnected } = useAccount();

  // Start Get Redux store variables
  const ether_to_usd = useSelector((state) => state.price.ether_to_usd); // Fetch the value of ether price from redux
  const titanx_to_usd = useSelector((state) => state.price.titanx_to_usd); // Fetch the value of TitanX price from redux
  const { switchChain } = useSwitchChain();
  const chainId = useChainId();

  const [ethCycle22Payout, setEthCycle22Payout] = useState(0);
  const [ethCycle69Payout, setEthCycle69Payout] = useState(0);
  const [ethCycle420Payout, setEthCycle420Payout] = useState(0);
  const [cycle22Payout, setCycle22Payout] = useState(0);
  const [cycle69Payout, setCycle69Payout] = useState(0);
  const [cycle420Payout, setCycle420Payout] = useState(0);
  const [claimableETH, setClaimableETH] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [rewardPoolEther, setRewardPoolEther] = useState(0);
  const [rewardPoolTitanx, setRewardPoolTitanx] = useState(0);
  const [claimableTitanX, setClaimableTitanX] = useState(0);
  const [userTitanx22, setuserTitanx22] = useState(0);
  const [userTitanx69, setuserTitanx69] = useState(0);
  const [userTitanx420, setuserTitanx420] = useState(0);
  const [userEther22, setuserEther22] = useState(0);
  const [userEther69, setuserEther69] = useState(0);
  const [userEther420, setuserEther420] = useState(0);
  const [userUsd22, setuserUsd22] = useState(0);
  const [userUsd69, setuserUsd69] = useState(0);
  const [userUsd420, setuserUsd420] = useState(0);

  useEffect(() => {
    if (chainId && chainId !== 1) {
      switchChain({ chainId: 1 });
    }
  }, [chainId, switchChain]);

  const heliosObj = {
    address: HeliosAddress,
    chainId: mainnet2.id,
    abi: heliosContract.abi,
  };

  const { data: userEst } = useReadContracts({
    contracts: [
      {
        ...heliosObj,
        functionName: "getGlobalActiveShares",
      },
      {
        ...heliosObj,
        functionName: "getUserCurrentActiveShares",
        args: [address],
      },
    ],
    watch: true,
  });

  const { data } = useReadContracts({
    contracts: [
      {
        ...heliosObj,
        functionName: "getGlobalActiveShares",
      },
      {
        ...heliosObj,
        functionName: "getETHCyclePayoutPool",
        args: [22],
      },
      {
        ...heliosObj,
        functionName: "getETHCyclePayoutPool",
        args: [69],
      },
      {
        ...heliosObj,
        functionName: "getETHCyclePayoutPool",
        args: [420],
      },
      {
        ...heliosObj,
        functionName: "getCyclePayoutPool",
        args: [22],
      },
      {
        ...heliosObj,
        functionName: "getCyclePayoutPool",
        args: [69],
      },
      {
        ...heliosObj,
        functionName: "getCyclePayoutPool",
        args: [420],
      },
      {
        ...heliosObj,
        functionName: "calculateUserCycleReward",
        args: [address, 22],
      },
      {
        ...heliosObj,
        functionName: "calculateUserCycleReward",
        args: [address, 69],
      },
      {
        ...heliosObj,
        functionName: "calculateUserCycleReward",
        args: [address, 420],
      },
      {
        ...heliosObj,
        functionName: "getUndistributedTitanX",
      },
      {
        ...heliosObj,
        functionName: "getUndistributedETH",
      },
      {
        ...heliosObj,
        functionName: "getUserETHClaimableTotal",
        args: [address],
      },
      {
        ...heliosObj,
        functionName: "getUserTitanXClaimableTotal",
        args: [address],
      },
    ],
    watch: true,
  });

  /**
   *@description: getCurrentBlockTimeStamp
   */
  const { data: cur_blockTimestamp } = useReadContract({
    address: HeliosAddress,
    chainId: mainnet2.id,
    abi: heliosContract.abi,
    functionName: "getCurrentBlockTimeStamp",
    watch: true,
  });
  /**
   *@description genesisTs
   */
  const { data: genesisTs } = useReadContract({
    address: HeliosAddress,
    chainId: mainnet2.id,
    abi: heliosContract.abi,
    functionName: "genesisTs",
    watch: true,
  });

  const { data: investaddress } = useReadContract({
    address: TreasuryAddress,
    chainId: mainnet2.id,
    abi: treasuryContract.abi,
    functionName: "getInvestmentPoolAddress",
    watch: true,
  });

  // Get getCurrentContractDay
  const { data: currentContractDay } = useReadContract({
    address: HeliosAddress,
    chainId: mainnet2.id,
    abi: heliosContract.abi,
    functionName: "getCurrentContractDay",
    watch: true,
  });

  useEffect(() => {
    if (data) {
      setClaimableTitanX(data[13] ? data[13]?.result?.toString() || 0 : 0);
      setRewardPoolEther(
        (
          parseFloat(etherToFixed(data[1] ? data[1]?.result || 0 : 0)) +
          parseFloat(etherToFixed(data[2] ? data[2]?.result || 0 : 0)) +
          parseFloat(etherToFixed(data[3] ? data[3]?.result || 0 : 0))
        ).toFixed(4)
      );
      setRewardPoolTitanx(
        (
          parseFloat(etherToFixed(data[4] ? data[4]?.result || 0 : 0)) +
          parseFloat(etherToFixed(data[5] ? data[5]?.result || 0 : 0)) +
          parseFloat(etherToFixed(data[6] ? data[6]?.result || 0 : 0))
        ).toFixed(2)
      );
      setClaimableETH(data[12] ? data[12]?.result?.toString() || 0 : 0);
    }
  }, [data]);

  /**
   *@description: Distribute payout
   */
  const {
    data: distributePayoutData,
    error: distributePayoutError,
    writeContract: distributePayout,
    isLoading: isDistributePayoutLoading,
  } = useWriteContract();

  const { isSuccess: isDistributePayoutConfirmed } =
    useWaitForTransactionReceipt({
      hash: distributePayoutData,
    });

  useEffect(() => {
    if (distributePayoutError) {
      toast.error(distributePayoutError.message.slice(0, 500) + "...", {
        autoClose: 5000,
      });
    }
  }, [distributePayoutError]);

  useEffect(() => {
    if (isDistributePayoutConfirmed) {
      toast.success("Distributed Successfully", {
        autoClose: 5000,
      });
    }
  }, [isDistributePayoutConfirmed]);

  const distributePayoutClicked = async () => {
    distributePayout({
      address: HeliosAddress,
      abi: heliosContract.abi,
      chainId: mainnet2.id,
      functionName: "triggerPayouts",
      args: [],
    });
  };

  /**
   *@description: Distribute TitanX
   */
  const {
    data: distributeTitanxData,
    writeContract: distributeTitanx,
    error: distributeTitanxError,
    isLoading: isdistributeTitanxLoading,
  } = useWriteContract();

  const { isSuccess: isDistributeTitanxConfirmed } =
    useWaitForTransactionReceipt({
      hash: distributeTitanxData,
    });

  useEffect(() => {
    if (distributeTitanxError) {
      toast.error(distributeTitanxError.message.slice(0, 500) + "...", {
        autoClose: 5000,
      });
    }
  }, [distributeTitanxError]);

  useEffect(() => {
    if (isDistributeTitanxConfirmed) {
      toast.success("Distributed Successfully", {
        autoClose: 5000,
      });
    }
  }, [isDistributeTitanxConfirmed]);

  const distributeTitanxClicked = async () => {
    distributeTitanx({
      address: HeliosAddress,
      abi: heliosContract.abi,
      chainId: mainnet2.id,
      functionName: "distributeTitanX",
      args: [],
    });
  };

  /**
   *@description  Start write contract function (claimallpayout)
   * */
  const {
    data: claimAllPayoutData,
    writeContract: claimAll,
    error: claimError,
    isLoading: isClaimAllLoading,
  } = useWriteContract();

  const { isSuccess: isClaimConfirmed } = useWaitForTransactionReceipt({
    hash: claimAllPayoutData,
  });

  useEffect(() => {
    if (claimError) {
      toast.error(claimError.message.slice(0, 50) + "...", {
        autoClose: 5000,
      });
    }
  }, [claimError]);

  useEffect(() => {
    if (isClaimConfirmed) {
      toast.success("Claimed successfully", {
        autoClose: 5000,
      });
    }
  }, [isClaimConfirmed]);

  const claimAllPayout = async () => {
    claimAll({
      address: HeliosAddress,
      abi: heliosContract.abi,
          chainId: mainnet2.id,
      functionName: "claimUserAvailablePayouts",
      args: [],
    });
  };

  const [getTwoProgress, setGetTwoprogress] = useState();
  const [getSixProgress, setGetSixprogress] = useState();
  const [getFourProgress, setGetFourprogress] = useState();

  function calculatePoolCompletionPercentage(
    currentContractDay,
    payoutCycleLength
  ) {
    // Calculate the number of days into the current payout cycle
    const daysIntoCurrentCycle = currentContractDay % payoutCycleLength;

    // Calculate the percentage of the cycle completed
    const percentageOfCycleCompleted =
      (daysIntoCurrentCycle / payoutCycleLength) * 100;

    return percentageOfCycleCompleted;
  }

  const { data: userBalance } = useBalance({
    address: investaddress ? investaddress : ethers.constants.AddressZero,
    chainId: mainnet.id,
  });

  useEffect(() => {
    if (data && data[1].status === "success") {
      setEthCycle22Payout(data[1] ? data[1]?.result?.toString() || 0 : 0);
      setEthCycle69Payout(data[2] ? data[2]?.result?.toString() || 0 : 0);
      setEthCycle420Payout(data[3] ? data[3]?.result?.toString() || 0 : 0);
      setCycle22Payout(data[4] ? data[4]?.result?.toString() || 0 : 0);
      setCycle69Payout(data[5] ? data[5]?.result?.toString() || 0 : 0);
      setCycle420Payout(data[6] ? data[6]?.result?.toString() || 0 : 0);

      if (
        (data[11] ? data[11]?.result?.toString() || 0 : 0) === 0 &&
        (data[10] ? data[10]?.result?.toString() || 0 : 0) === 0
      ) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
      if (userEst && userEst[0].status === "success") {
        if (parseFloat(userEst[0]?.result || 0) !== 0) {
          const userEstRate =
            (userEst[1] ? userEst[1]?.result?.toString() || 0 : 0) /
            (userEst[0] ? userEst[0]?.result?.toString() || 0 : 0);
          setuserTitanx22(
            etherToFixed(data[4] ? data[4]?.result || 0 : 0) * userEstRate
          );
          setuserTitanx69(
            etherToFixed(data[5] ? data[5]?.result || 0 : 0) * userEstRate
          );
          setuserTitanx420(
            etherToFixed(data[6] ? data[6]?.result || 0 : 0) * userEstRate
          );
          setuserEther22(
            etherToFixed(data[1] ? data[1]?.result || 0 : 0) * userEstRate
          );
          setuserEther69(
            etherToFixed(data[2] ? data[2]?.result || 0 : 0) * userEstRate
          );
          setuserEther420(
            etherToFixed(data[3] ? data[3]?.result || 0 : 0) * userEstRate
          );

          setuserUsd22(
            etherToFixed(data[4] ? data[4]?.result || 0 : 0) *
              userEstRate *
              titanx_to_usd +
              etherToFixed(data[1] ? data[1]?.result || 0 : 0) *
                userEstRate *
                ether_to_usd
          );
          setuserUsd69(
            etherToFixed(data[5] ? data[5]?.result || 0 : 0) *
              userEstRate *
              titanx_to_usd +
              etherToFixed(data[2] ? data[2]?.result || 0 : 0) *
                userEstRate *
                ether_to_usd
          );
          setuserUsd420(
            etherToFixed(data[6] ? data[6]?.result || 0 : 0) *
              userEstRate *
              titanx_to_usd +
              etherToFixed(data[3] ? data[3]?.result || 0 : 0) *
                userEstRate *
                ether_to_usd
          );
        }
      }
    }

    setGetTwoprogress(
      calculatePoolCompletionPercentage(
        currentContractDay ? parseInt(currentContractDay.toString()) : 0,
        22
      )
    );
    setGetSixprogress(
      calculatePoolCompletionPercentage(
        currentContractDay ? parseInt(currentContractDay.toString()) : 0,
        69
      )
    );
    setGetFourprogress(
      calculatePoolCompletionPercentage(
        currentContractDay ? parseInt(currentContractDay.toString()) : 0,
        420
      )
    );
  }, [
    data,
    disableButton,
    userBalance,
    currentContractDay,
    ether_to_usd,
    titanx_to_usd,
    userEst,
  ]);

  const heliosContractObj = {
    address: HeliosAddress,
    abi: heliosContract.abi,
  };
  const { data: datawithcount } = useReadContracts({
    contracts: [
      {
        ...heliosContractObj,
        functionName: "getNextCyclePayoutDay",
        args: [22],
      },
      {
        ...heliosContractObj,
        functionName: "getNextCyclePayoutDay",
        args: [69],
      },
      {
        ...heliosContractObj,
        functionName: "getNextCyclePayoutDay",
        args: [420],
      },
    ],
    watch: true,
  });
  const [twoNextPayoutDay, setTwoNextPayoutDay] = useState(0);
  const [fourNextPayoutDay, setFourNextPayoutDay] = useState(0);
  const [sixNextPayoutDay, setSixNextPayoutDay] = useState(0);
  const [twoCountDown, setTwoCountDown] = useState("");
  const [sixCountDown, setSixCountDown] = useState("");
  const [fourCountDown, setFourCountDown] = useState("");
  useEffect(() => {
    if (datawithcount && datawithcount[0].status === "success") {
      setTwoNextPayoutDay(datawithcount[0]?.result?.toString());
      setSixNextPayoutDay(datawithcount[1]?.result?.toString());
      setFourNextPayoutDay(datawithcount[2]?.result?.toString());
      const two_next_day = datawithcount[0]?.result?.toString();
      const six_next_day = datawithcount[1]?.result?.toString();
      const four_next_day = datawithcount[2]?.result?.toString();
      const genesisTs_timestamp = genesisTs?.toString();
      const current_timestamp = cur_blockTimestamp?.toString();

      const calculateTimeDifference = (
        timestamp,
        currentTimestamp,
        genesisTs
      ) => {
        const timestampSeconds = timestamp * 86400; // Convert timestamp to seconds
        const timeDifferenceSeconds =
          timestampSeconds - (currentTimestamp - genesisTs) - 86400; // Calculate difference in seconds
        const days = Math.floor(timeDifferenceSeconds / 86400); // Calculate number of days
        const remainingSeconds = timeDifferenceSeconds % 86400; // Calculate remaining seconds after subtracting days
        const date = new Date(remainingSeconds * 1000); // Convert remaining seconds to date
        const hours = date.getUTCHours().toString().padStart(2, "0");
        const minutes = date.getUTCMinutes().toString().padStart(2, "0");
        const seconds = date.getUTCSeconds().toString().padStart(2, "0");
        return `${days}D:${hours}H:${minutes}M:${seconds}S`;
      };

      const two_next_day_formatted = calculateTimeDifference(
        two_next_day,
        current_timestamp,
        genesisTs_timestamp
      );
      const six_next_day_formatted = calculateTimeDifference(
        six_next_day,
        current_timestamp,
        genesisTs_timestamp
      );
      const four_next_day_formatted = calculateTimeDifference(
        four_next_day,
        current_timestamp,
        genesisTs_timestamp
      );
      setTwoCountDown(two_next_day_formatted);
      setSixCountDown(six_next_day_formatted);
      setFourCountDown(four_next_day_formatted);
    }
  }, [datawithcount, cur_blockTimestamp, genesisTs]);
  return (
    <RewardContentStyleWrapper>
      <div className="reward_container">
        <div className="ether_subtitle">
          <h2 className="assets_under_management">Staking Payouts </h2>
        </div>
        <div className="rows">
          <div className="cols">
            <div className="reward_pool_cards">
              <h1 className="cycle_reward_pool">Cycle Reward Pools</h1>
              <div className="cards_contents">
                <h3>22 DAY</h3>
                <div className="card_content_rewards">
                  <RewardPoolPayout
                    name="Global Payout"
                    titanx={etherToFixed(
                      cycle22Payout ? cycle22Payout : 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    ether={etherToFixed(
                      ethCycle22Payout ? ethCycle22Payout : 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                    usd={(
                      parseFloat(
                        etherToFixed(ethCycle22Payout ? ethCycle22Payout : 0)
                      ) *
                        ether_to_usd +
                      parseFloat(etherToFixed(cycle22Payout)) * titanx_to_usd
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "GlobalPayout" in asset
                      )?.GlobalPayout
                    }
                  />
                  <RewardPoolPayout
                    name="Your Est. Payout"
                    type="rewardpool"
                    titanx={(userTitanx22 ? userTitanx22 : 0).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                    )}
                    ether={(userEther22 ? userEther22 : 0).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 4, maximumFractionDigits: 4 }
                    )}
                    usd={(userUsd22 ? userUsd22 : 0).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "yourestpayout" in asset
                      )?.yourestpayout
                    }
                  />
                </div>
                <div className="progress_content">
                  <ProgressBar
                    className="progress_bar"
                    completed={
                      getTwoProgress ? parseFloat(getTwoProgress.toFixed(0)) : 0
                    }
                    bgColor="linear-gradient(90deg, rgba(254,156,1,1) 13%, rgba(186,53,5,1) 87%)"
                    isLabelVisible={true}
                  />
                  <Tooltip
                    text={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "progressbar" in asset
                      )?.progressbar
                    }
                  >
                    <AiOutlineQuestionCircle />
                  </Tooltip>
                </div>
                <div className="nextpayoutday_countdown">
                  <div className="nextpayoutday">
                    <div className="nextpayoutday_title">
                      <span>Next Payout Day:</span>
                      <span className="number_next_day">
                        {" "}
                        {twoNextPayoutDay}
                      </span>
                    </div>
                    <div className="countdown_value">{twoCountDown}</div>
                  </div>
                </div>
              </div>
              <div className="cards_contents">
                <h3>69 DAY</h3>
                <div className="card_content_rewards">
                  <RewardPoolPayout
                    name="Global Payout"
                    titanx={etherToFixed(
                      cycle69Payout ? cycle69Payout : 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    ether={etherToFixed(
                      ethCycle69Payout ? ethCycle69Payout : 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                    usd={(
                      parseFloat(
                        etherToFixed(ethCycle69Payout ? ethCycle69Payout : 0)
                      ) *
                        ether_to_usd +
                      parseFloat(etherToFixed(cycle69Payout)) * titanx_to_usd
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "GlobalPayout" in asset
                      )?.GlobalPayout
                    }
                  />
                  <RewardPoolPayout
                    name="Your Est. Payout"
                    type="rewardpool"
                    titanx={(userTitanx69 ? userTitanx69 : 0).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                    )}
                    ether={(userEther69 ? userEther69 : 0).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 4, maximumFractionDigits: 4 }
                    )}
                    usd={(userUsd69 ? userUsd69 : 0).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "yourestpayout" in asset
                      )?.yourestpayout
                    }
                  />
                </div>
                <div className="progress_content">
                  <ProgressBar
                    className="progress_bar"
                    completed={
                      getSixProgress ? parseFloat(getSixProgress.toFixed(0)) : 0
                    }
                    bgColor="linear-gradient(90deg, rgba(254,156,1,1) 13%, rgba(186,53,5,1) 87%)"
                    isLabelVisible={true}
                  />
                  <Tooltip
                    text={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "progressbar" in asset
                      )?.progressbar
                    }
                  >
                    <AiOutlineQuestionCircle />
                  </Tooltip>
                </div>
                <div className="nextpayoutday_countdown">
                  <div className="nextpayoutday">
                    <div className="nextpayoutday_title">
                      <span>Next Payout Day:</span>
                      <span className="number_next_day">
                        {" "}
                        {sixNextPayoutDay}
                      </span>
                    </div>
                    <div className="countdown_value">{sixCountDown}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cols">
            <div className="reward_pool_cards">
              <div className="cards_contents">
                <h3>420 DAY</h3>
                <div className="card_content_rewards">
                  <RewardPoolPayout
                    name="Global Payout"
                    titanx={etherToFixed(
                      cycle420Payout ? cycle420Payout : 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    ether={etherToFixed(
                      ethCycle420Payout ? ethCycle420Payout : 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                    usd={(
                      parseFloat(
                        etherToFixed(ethCycle420Payout ? ethCycle420Payout : 0)
                      ) *
                        ether_to_usd +
                      parseFloat(etherToFixed(cycle420Payout)) * titanx_to_usd
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "GlobalPayout" in asset
                      )?.GlobalPayout
                    }
                  />
                  <RewardPoolPayout
                    name="Your Est. Payout"
                    type="rewardpool"
                    titanx={(userTitanx420 ? userTitanx420 : 0).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                    )}
                    ether={(userEther420 ? userEther420 : 0).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 4, maximumFractionDigits: 4 }
                    )}
                    usd={(userUsd420 ? userUsd420 : 0).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "yourestpayout" in asset
                      )?.yourestpayout
                    }
                  />
                </div>
                <div className="progress_content">
                  <ProgressBar
                    className="progress_bar"
                    completed={
                      getFourProgress
                        ? parseFloat(getFourProgress.toFixed(0))
                        : 0
                    }
                    bgColor="linear-gradient(90deg, rgba(254,156,1,1) 13%, rgba(186,53,5,1) 87%)"
                    isLabelVisible={true}
                  />
                  <Tooltip
                    text={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "progressbar" in asset
                      )?.progressbar
                    }
                  >
                    <AiOutlineQuestionCircle />
                  </Tooltip>
                </div>
                <div className="nextpayoutday_countdown">
                  <div className="nextpayoutday">
                    <div className="nextpayoutday_title">
                      <span>Next Payout Day:</span>
                      <span className="number_next_day">
                        {" "}
                        {fourNextPayoutDay}
                      </span>
                    </div>
                    {/* <div className="nextpayoutday_value">{fourNextPayoutDay}</div> */}
                    <div className="countdown_value">{fourCountDown}</div>
                  </div>
                </div>
                <div className="reward_pool_btn_group">
                  <DistTinethTooltip>
                    <button
                      className="distribute_button"
                      id="disttitaneth"
                      disabled={
                        disableButton ||
                        !(isConnected && !isdistributeTitanxLoading)
                      }
                      onClick={distributeTitanxClicked}
                    >
                      DISTRIBUTE TITANX/ETH
                    </button>
                  </DistTinethTooltip>
                  <DistPayoutTooltip>
                    <button
                      className="distribute_button"
                      disabled={!(isConnected && !isDistributePayoutLoading)}
                      onClick={distributePayoutClicked}
                      // onClick={toggleDistPayout}
                    >
                      DISTRIBUTE PAYOUTS
                    </button>
                  </DistPayoutTooltip>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="rewards_cards">
                <Link to="/rewardpools" className="title_with_tooltip">
                  <h1 className="title_h1_cards">Rewards</h1>
                </Link>
                <div
                  className="cards_content"
                  style={{ marginBottom: "13px", fontWeight: "bold" }}
                >
                  <div className="cards_content_text">
                    <RewardPoolPayout
                      name="Cycle Reward Pool"
                      titanx={parseFloat(rewardPoolTitanx).toLocaleString(
                        "en-US",
                        { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                      )}
                      ether={parseFloat(rewardPoolEther).toLocaleString(
                        "en-US",
                        { minimumFractionDigits: 4 }
                      )}
                      usd={(
                        rewardPoolTitanx * titanx_to_usd +
                        rewardPoolEther * ether_to_usd
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      tooltip={
                        tooltipText.dashboard[1].rewards.find(
                          (assets) => "cyclerewardpool" in assets
                        )?.cyclerewardpool
                      }
                    />
                    <RewardPoolPayout
                      name="Your Est. Payouts"
                      type="rewardpool"
                      titanx={parseFloat(
                        userTitanx22 + userTitanx69 + userTitanx420
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                      ether={parseFloat(
                        userEther22 + userEther69 + userEther420
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 4,
                        maximumFractionDigits: 4,
                      })}
                      usd={calculateTotalPrice(
                        calculatePrice(
                          parseFloat(
                            userTitanx22 + userTitanx69 + userTitanx420
                          ),
                          titanx_to_usd
                        ),
                        calculatePrice(
                          parseFloat(userEther22 + userEther69 + userEther420),
                          ether_to_usd
                        )
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      tooltip={
                        tooltipText.rewardpools[0].leftbox.find(
                          (asset) => "totalPayout" in asset
                        )?.totalPayout
                      }
                    />
                    <RewardPoolPayout
                      type="rewardpool"
                      name="Claimable Rewards"
                      titanx={parseFloat(
                        etherToFixed(claimableTitanX)
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                      ether={parseFloat(
                        etherToFixed(claimableETH)
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 4,
                        maximumFractionDigits: 4,
                      })}
                      usd={parseFloat(
                        calculateTotalPrice(
                          calculatePrice(
                            etherToFixed(claimableTitanX),
                            titanx_to_usd
                          ),
                          calculatePrice(
                            etherToFixed(claimableETH),
                            ether_to_usd
                          )
                        )
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      tooltip={
                        tooltipText.dashboard[1].rewards.find(
                          (asset) => "claimablerewards" in asset
                        )?.claimablerewards
                      }
                    />
                    {/* <div style={{ height: '17px', visibility: 'hidden' }}>ee</div> */}
                  </div>
                </div>
                <div className="btn_group">
                  <button
                    className="claim"
                    onClick={claimAllPayout}
                    disabled={!(isConnected && !isClaimAllLoading)}
                  >
                    CLAIM
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RewardContentStyleWrapper>
  );
};;;;;;;;;;;;;;;;;;;;;;;;;
export default RewardPoolContent;
