import React from "react";
import "./Content.css";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import RewardPoolPayout from "../../../common/rewardspoolpayout/RewardPoolPayout";
import heliosContract from "../../../contract/helios.json";
import { useAccount, useReadContracts, useWriteContract, useWaitForTransactionReceipt, useBalance, useSwitchChain, useChainId } from "wagmi";
import heliosAvatar from "../../../assets/helius/HLX.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { HeliosAddress, TitanxAddress, calculatePrice, calculateTotalPrice, etherToFixed } from "../../../const/const";
import tooltipText from "../../../assets/helius/tooltipText.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "../../../common/tooltip";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { mainnet } from "viem/chains";

const Content = () => {
    // Start Get Redux store variables
    const ether_to_usd = useSelector((state) => state.price.ether_to_usd); // Fetch the value of ether price from redux
    const titanx_to_usd = useSelector((state) => state.price.titanx_to_usd); // Fetch the value of TitanX price from redux
    const hlx_to_usd = useSelector((state) => state.price.hlx_to_usd); // Fetch the value of HLX price from redux
    // End Get Redux store variables

    const { switchChain } = useSwitchChain();
    const chainId = useChainId();

    const [userEthBalance, setUserEthBalance] = useState(0);
    const [userTitanBalance, setUserTitanBalance] = useState(0);
    const [userHlxBalance, setUserHlxBalance] = useState(0);
    const [rewardPoolEther, setRewardPoolEther] = useState(0);
    const [rewardPoolTitanx, setRewardPoolTitanx] = useState(0);
    const [claimableTitanX, setClaimableTitanX] = useState(0);
    const [claimableETH, setClaimableETH] = useState(0);
    const [userMints, setUserMints] = useState([]);
    const [estimatedRewards, setEstimatedRewards] = useState([]);

    const [userStakes, setUserStakes] = useState([]);
    const [timeStamp, setTimeStamp] = useState(0);

    const [getUserActiveStakes, setGetUserActiveStakes] = useState([]);
    const [getUserClaimableStakes, setGetUserClaimableStakes] = useState([]);
    const [getUserEndedStakes, setGetUserEndedStakes] = useState([]);

    const [getUserActiveMints, setGetUserActiveMints] = useState([]);
    const [getUserClaimableMints, setGetUserClaimableMints] = useState([]);
    const [getUserEndedMints, setGetUserEndedMints] = useState([]);

    const { address, isConnected } = useAccount();

    const heliosObj = {
        address: HeliosAddress,
        abi: heliosContract.abi,
        chainId: mainnet.id,
    };

    const titanxObj = {
        address: TitanxAddress,
        abi: heliosContract.abi,
        chainId: mainnet.id,
    };

    useEffect(() => {
        if (chainId && chainId !== 1) {
            switchChain({ chainId: 1 });
        }
    }, [chainId, switchChain]);

    // Reads from contract function
    const { data } = useReadContracts({
        contracts: [
            {
                ...heliosObj,
                functionName: "getUserTitanXClaimableTotal",
                args: [address],
            },
            {
                ...heliosObj,
                functionName: "balanceOf",
                args: [address],
            },
            {
                ...titanxObj,
                functionName: "balanceOf",
                args: [address],
            },
            {
                ...heliosObj,
                functionName: "getETHCyclePayoutPool",
                args: [22],
            },
            {
                ...heliosObj,
                functionName: "getETHCyclePayoutPool",
                args: [69],
            },
            {
                ...heliosObj,
                functionName: "getETHCyclePayoutPool",
                args: [420],
            },
            {
                ...heliosObj,
                functionName: "getCyclePayoutPool",
                args: [22],
            },
            {
                ...heliosObj,
                functionName: "getCyclePayoutPool",
                args: [69],
            },
            {
                ...heliosObj,
                functionName: "getCyclePayoutPool",
                args: [420],
            },
            {
                ...heliosObj,
                functionName: "getUserETHClaimableTotal",
                args: [address],
            },
            {
                ...heliosObj,
                functionName: "getUserStakes",
                args: [address],
            },
            {
                ...heliosObj,
                functionName: "getUserMints",
                args: [address],
            },
            {
                ...heliosObj,
                functionName: "getCurrentBlockTimeStamp",
            },
        ],
        watch: true,
    });

    useEffect(() => {
        const fetchEstimatedRewards = async () => {
            if (!address || !userMints.length) return;
            const provider = new ethers.providers.JsonRpcProvider("https://lb.nodies.app/v1/0a6ecd2caf9f4cb8a10685841dbcb9fe");
            const contract = new ethers.Contract(HeliosAddress, heliosContract.abi, provider);

            const rewardsPromises = userMints.map((mint) =>
                contract.estimateMintReward(address, mint.mId).then(
                    (reward) => ({
                        mintId: mint.mId,
                        reward: ethers.utils.formatEther(reward),
                    }),
                    (error) => ({
                        mintId: mint.mId,
                        error,
                    })
                )
            );

            try {
                const rewards = await Promise.all(rewardsPromises);
                setEstimatedRewards(rewards); // Update state with the fetched rewards
            } catch (error) {
                console.error("Error fetching estimated rewards:", error);
            }
        };
        fetchEstimatedRewards();
    }, [address, userMints]);

    /**
     *@description  Start write contract function (claimallpayout)
     * */
    const { data: claimAllPayoutData, writeContract: claimAll, error: claimError, isLoading: isClaimAllLoading } = useWriteContract();

    const { isSuccess: isClaimConfirmed } = useWaitForTransactionReceipt({
        hash: claimAllPayoutData,
    });

    useEffect(() => {
        if (claimError) {
            toast.error(claimError.message.slice(0, 50) + "...", {
                autoClose: 5000,
            });
        }
    }, [claimError]);

    useEffect(() => {
        if (isClaimConfirmed) {
            toast.success("Claimed successfully", {
                autoClose: 5000,
            });
        }
    }, [isClaimConfirmed]);

    const claimAllPayout = async () => {
        claimAll({
            address: HeliosAddress,
            abi: heliosContract.abi,
            chain: mainnet.id,
            functionName: "claimUserAvailablePayouts",
            args: [],
        });
    };

    const getTotalMintHlxAmount = (mints, currentTimeStamp) => {
        let total = 0;
        mints.forEach((mint) => {
            if (estimatedRewards[parseInt(mint.mId) - 1]) {
                if (mint.mintInfo.status === 0 && mint.mintInfo.maturityTs > currentTimeStamp) {
                    total += estimatedRewards[parseInt(mint.mId) - 1].reward - parseFloat(etherToFixed(mint.mintInfo.mintableHlx));
                } else if (mint.mintInfo.status === 0 && mint.mintInfo.maturityTs <= currentTimeStamp) {
                    total += estimatedRewards[parseInt(mint.mId) - 1].reward - parseFloat(etherToFixed(mint.mintInfo.mintableHlx));
                } else {
                    total += parseFloat(etherToFixed(mint.mintInfo.mintedHlx));
                }
            }
        });
        return total;
    };

    const getTotalMintCost = (mints) => {
        let total = 0;

        mints.forEach((mint) => {
            total += parseFloat(etherToFixed(mint.mintInfo.mintCost)) + parseFloat(etherToFixed(mint.mintInfo.titanBurned));
        });
        return total;
    };
    const getTotalHlxStaked = (stakes) => {
        let total = 0;

        stakes.forEach((mint) => {
            total += parseFloat(etherToFixed(mint.stakeInfo.hlxAmount));
        });
        return total;
    };

    const getTotalHlxShares = (stakes) => {
        let total = 0;

        stakes.forEach((mint) => {
            total += parseFloat(etherToFixed(mint.stakeInfo.shares));
        });
        return total;
    };

    const processUserItems = (userItems, currentTimeStamp) => {
        const userActiveItems = [];
        const userClaimableItems = [];
        const userEndedItems = [];

        userItems.forEach((item) => {
            const itemInfo = item.mintInfo || item.stakeInfo;
            if (itemInfo.maturityTs > currentTimeStamp && itemInfo.status === 0) {
                userActiveItems.push(item);
            } else if (itemInfo.maturityTs <= currentTimeStamp && itemInfo.status === 0) {
                userClaimableItems.push(item);
            } else if (itemInfo.status === 1 || itemInfo.status === 2) {
                userEndedItems.push(item);
            }
        });

        return {
            activeItems: userActiveItems,
            claimableItems: userClaimableItems,
            endedItems: userEndedItems,
        };
    };

    const { data: userBalance } = useBalance({
        address: address,
        chainId: mainnet.id,
    });

    useEffect(() => {
        setUserEthBalance(ethers.utils.formatEther(userBalance ? userBalance.value.toString() : 0));
    }, [userBalance]);

    useEffect(() => {
        if (data) {
            setClaimableTitanX(data[0] ? data[0]?.result?.toString() || 0 : 0);
            setUserHlxBalance(data[1] ? data[1]?.result?.toString() || 0 : 0);
            setUserTitanBalance(data[2] ? data[2]?.result?.toString() || 0 : 0);
            setRewardPoolEther(
                (
                    parseFloat(etherToFixed(data[3] ? data[3]?.result || 0 : 0)) +
                    parseFloat(etherToFixed(data[4] ? data[4]?.result || 0 : 0)) +
                    parseFloat(etherToFixed(data[5] ? data[5]?.result || 0 : 0))
                ).toFixed(4)
            );
            setRewardPoolTitanx(
                (
                    parseFloat(etherToFixed(data[6] ? data[6]?.result || 0 : 0)) +
                    parseFloat(etherToFixed(data[7] ? data[7]?.result || 0 : 0)) +
                    parseFloat(etherToFixed(data[8] ? data[8]?.result || 0 : 0))
                ).toFixed(2)
            );
            setClaimableETH(data[9] ? data[9]?.result?.toString() || 0 : 0);
            setUserStakes(data[10] ? data[10]?.result || 0 : 0);
            setUserMints(data[11] ? data[11]?.result || 0 : 0);
            setTimeStamp(data[12] ? data[12]?.result?.toString() || 0 : 0);
        }

        if (userStakes) {
            const { activeItems: activeStakes, claimableItems: claimableStakes, endedItems: endedStakes } = processUserItems(userStakes, timeStamp);
            setGetUserActiveStakes(activeStakes);
            setGetUserClaimableStakes(claimableStakes);
            setGetUserEndedStakes(endedStakes);
        }

        if (userMints) {
            const { activeItems: activeMints, claimableItems: claimableMints, endedItems: endedMints } = processUserItems(userMints, timeStamp);
            setGetUserActiveMints(activeMints);
            setGetUserClaimableMints(claimableMints);
            setGetUserEndedMints(endedMints);
        }
    }, [data, userStakes, userMints]);

    const buyTtanxClicked = () => {
        window.open(
            "https://app.uniswap.org/swap?exactField=input&chain=mainnet&inputCurrency=ETH&outputCurrency=0xF19308F923582A6f7c465e5CE7a9Dc1BEC6665B1",
            "_blank"
        );
    };

    const buyHLXClicked = () => {
        window.open(
            "https://app.uniswap.org/swap?exactField=input&chain=mainnet&inputCurrency=0xF19308F923582A6f7c465e5CE7a9Dc1BEC6665B1&outputCurrency=0x2614f29c39de46468a921fd0b41fdd99a01f2edf",
            "_blank"
        );
    };

    return (
        <div className="full">
            <div className="wrapper">
                <div className="dashboard_container">
                    <div className="ether_subtitle">
                        <h2 className="first_title_helius">Your </h2>{" "}
                        <span>
                            <img src={heliosAvatar} alt="" />{" "}
                        </span>
                        <h2 className="second_title_helius">Helios Ecosystem at a Glance </h2>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="assets_cards">
                                <h1 className="title_h1_cards">Assets</h1>

                                <div className="cards_content">
                                    <div className="cards_content_text">
                                        <table className="reward_pool_count_table">
                                            <tbody>
                                                <tr>
                                                    <td className="title_amount_titanx_td">
                                                        <div className="tooltip_text_dashboard">
                                                            <p style={{ fontWeight: "bold", margin: "0" }}>Ethereum</p>
                                                            <Tooltip
                                                                text={tooltipText.dashboard[0].assets.find((asset) => "ethereum" in asset)?.ethereum}
                                                            >
                                                                <AiOutlineQuestionCircle />
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                    <td className="amount_titanx_td" style={{ fontWeight: "bold", margin: "0" }}>
                                                        {parseFloat(userEthBalance).toLocaleString("en-US", {
                                                            minimumFractionDigits: 4,
                                                            maximumFractionDigits: 4,
                                                        })}{" "}
                                                    </td>
                                                    <td className="titanx_name_td" style={{ fontWeight: "bold", margin: "0" }}>
                                                        ETH
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        className="amount_usd_td dashboard_tr"
                                                        style={{ fontWeight: "bold", margin: "0" }}
                                                    >
                                                        ≈ $
                                                        {`${parseFloat(calculatePrice(userEthBalance, ether_to_usd)).toLocaleString("en-US", {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        })}`}
                                                    </td>
                                                    <td className="name_usd_td dashboard_tr" style={{ fontWeight: "bold", margin: "0" }}>
                                                        USD
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="title_amount_titanx_td">
                                                        <div className="tooltip_text_dashboard">
                                                            <p style={{ fontWeight: "bold", margin: "0" }}>TitanX</p>
                                                            <Tooltip
                                                                text={tooltipText.dashboard[0].assets.find((asset) => "titanx" in asset)?.titanx}
                                                            >
                                                                <AiOutlineQuestionCircle />
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                    <td className="amount_titanx_td" style={{ fontWeight: "bold", margin: "0" }}>
                                                        {etherToFixed(userTitanBalance).toLocaleString("en-US", {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                        })}{" "}
                                                    </td>
                                                    <td className="titanx_name_td" style={{ fontWeight: "bold", margin: "0" }}>
                                                        TITANX
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        className="amount_usd_td dashboard_tr"
                                                        style={{ fontWeight: "bold", margin: "0" }}
                                                    >
                                                        ≈ $
                                                        {`${parseFloat(calculatePrice(etherToFixed(userTitanBalance), titanx_to_usd)).toLocaleString(
                                                            "en-US",
                                                            {
                                                                minimumFractionDigits: 2,
                                                            }
                                                        )}`}
                                                    </td>
                                                    <td className="name_usd_td dashboard_tr" style={{ fontWeight: "bold", margin: "0" }}>
                                                        USD
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="title_amount_titanx_td">
                                                        <div className="tooltip_text_dashboard">
                                                            <p style={{ fontWeight: "bold", margin: "0" }}>Helios</p>
                                                            <Tooltip
                                                                text={tooltipText.dashboard[0].assets.find((asset) => "Helios" in asset)?.Helios}
                                                            >
                                                                <AiOutlineQuestionCircle />
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                    <td className="amount_titanx_td" style={{ fontWeight: "bold", margin: "0" }}>
                                                        {parseFloat(etherToFixed(userHlxBalance)).toLocaleString("en-US", {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                        })}
                                                    </td>
                                                    <td className="titanx_name_td" style={{ fontWeight: "bold", margin: "0" }}>
                                                        HLX
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        className="amount_usd_td dashboard_tr"
                                                        style={{ fontWeight: "bold", margin: "0" }}
                                                    >
                                                        ≈ $
                                                        {`${parseFloat(calculatePrice(etherToFixed(userHlxBalance), hlx_to_usd)).toLocaleString(
                                                            "en-US",
                                                            {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )}`}
                                                    </td>
                                                    <td className="name_usd_td dashboard_tr" style={{ fontWeight: "bold", margin: "0" }}>
                                                        USD
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="btn_group">
                                    <button className="buy_hlx" onClick={buyHLXClicked}>
                                        Buy HLX
                                    </button>
                                    <button className="buy_hlx" onClick={buyTtanxClicked}>
                                        Buy TITANX
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="rewards_cards_dashboard">
                                <Link to="/rewardpools" className="title_with_tooltip">
                                    <h1 className="title_h1_cards">Rewards</h1>
                                </Link>
                                <div className="cards_content" style={{ marginBottom: "13px", fontWeight: "bold" }}>
                                    <div className="cards_content_text">
                                        <RewardPoolPayout
                                            name="Cycle Reward Pool"
                                            titanx={parseFloat(rewardPoolTitanx).toLocaleString("en-US", {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            })}
                                            ether={parseFloat(rewardPoolEther).toLocaleString("en-US", { minimumFractionDigits: 4 })}
                                            usd={(rewardPoolTitanx * titanx_to_usd + rewardPoolEther * ether_to_usd).toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                            tooltip={tooltipText.dashboard[1].rewards.find((assets) => "cyclerewardpool" in assets)?.cyclerewardpool}
                                        />
                                        <RewardPoolPayout
                                            type="rewardpool"
                                            name="Claimable Rewards"
                                            titanx={parseFloat(etherToFixed(claimableTitanX)).toLocaleString("en-US", {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            })}
                                            ether={parseFloat(etherToFixed(claimableETH)).toLocaleString("en-US", {
                                                minimumFractionDigits: 4,
                                                maximumFractionDigits: 4,
                                            })}
                                            usd={parseFloat(
                                                calculateTotalPrice(
                                                    calculatePrice(etherToFixed(claimableTitanX), titanx_to_usd),
                                                    calculatePrice(etherToFixed(claimableETH), ether_to_usd)
                                                )
                                            ).toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                            tooltip={tooltipText.dashboard[1].rewards.find((asset) => "claimablerewards" in asset)?.claimablerewards}
                                        />
                                        {/* <div style={{ height: '17px', visibility: 'hidden' }}>ee</div> */}
                                    </div>
                                </div>
                                <div className="btn_group">
                                    <button className="claim" onClick={claimAllPayout} disabled={!(isConnected && !isClaimAllLoading)}>
                                        CLAIM
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="miners_cards">
                                <Link to="/mine">
                                    <h1 className="title_h1_cards">Miners</h1>
                                </Link>
                                {/* <div className="table_text">
                                    
                                </div> */}
                                <div className="cards_content">
                                    <table className="cards_content_table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Total</th>
                                                <th>Est. HLX w/hRank Bonus</th>
                                                <th>ROI</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="active_td">Active</td>
                                                <td>{getUserActiveMints.length}</td>
                                                <td>
                                                    {parseFloat(getTotalMintHlxAmount(getUserActiveMints, timeStamp)).toLocaleString("en-US", {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}
                                                </td>
                                                {/* Calculate 'temp' outside of JSX */}
                                                {(() => {
                                                    const temp = parseFloat(
                                                        ((hlx_to_usd * getTotalMintHlxAmount(getUserActiveMints, timeStamp)) /
                                                            (getTotalMintCost(getUserActiveMints) * titanx_to_usd) -
                                                            1) *
                                                            100
                                                    ).toLocaleString("en-US", {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    });
                                                    if (temp > 0) {
                                                        return <td className="green_font_color">{"+ " + temp + " %"}</td>;
                                                    } else {
                                                        return <td>{temp + "%"}</td>;
                                                    }
                                                })()}
                                            </tr>
                                            <tr>
                                                <td className="claimable_td">Claimable</td>
                                                <td>{getUserClaimableMints.length}</td>
                                                <td>
                                                    {parseFloat(getTotalMintHlxAmount(getUserClaimableMints, timeStamp)).toLocaleString("en-US", {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}
                                                </td>
                                                {(() => {
                                                    const temp = (
                                                        ((hlx_to_usd * getTotalMintHlxAmount(getUserClaimableMints, timeStamp)) /
                                                            (getTotalMintCost(getUserClaimableMints) * titanx_to_usd) -
                                                            1) *
                                                        100
                                                    ).toFixed(2);
                                                    if (temp > 0) {
                                                        return <td className="green_font_color">{"+ " + temp + " %"}</td>;
                                                    } else {
                                                        return <td>{temp + "%"}</td>;
                                                    }
                                                })()}
                                            </tr>
                                            <tr>
                                                <td className="ended_td">Ended</td>
                                                <td>{getUserEndedMints.length}</td>
                                                <td>
                                                    {parseFloat(getTotalMintHlxAmount(getUserEndedMints, timeStamp)).toLocaleString("en-US", {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="staked_cards">
                                <Link to="/stake">
                                    <h1 className="title_h1_cards">Staked</h1>
                                </Link>

                                <div className="cards_content">
                                    <table className="cards_content_table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Total</th>
                                                <th>HLX Staked</th>
                                                <th>Shares</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="active_td">Active</td>
                                                <td>{getUserActiveStakes.length}</td>
                                                <td>
                                                    {parseFloat(getTotalHlxStaked(getUserActiveStakes)).toLocaleString("en-US", {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}
                                                </td>
                                                <td>
                                                    {parseFloat(getTotalHlxShares(getUserActiveStakes)).toLocaleString("en-US", {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </td>
                                                <td>
                                                    {"$ " +
                                                        parseFloat(
                                                            hlx_to_usd * parseFloat(getTotalHlxStaked(getUserActiveStakes)).toFixed(2)
                                                        ).toFixed(2)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="claimable_td">Claimable</td>
                                                <td>{getUserClaimableStakes.length}</td>
                                                <td>
                                                    {parseFloat(getTotalHlxStaked(getUserClaimableStakes)).toLocaleString("en-US", {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}
                                                </td>
                                                <td>
                                                    {parseFloat(getTotalHlxShares(getUserClaimableStakes)).toLocaleString("en-US", {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </td>
                                                <td>
                                                    {"$ " +
                                                        parseFloat(
                                                            hlx_to_usd * parseFloat(getTotalHlxStaked(getUserClaimableStakes)).toFixed(2)
                                                        ).toFixed(2)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="ended_td">Ended</td>
                                                <td>{getUserEndedStakes.length}</td>
                                                <td>
                                                    {parseFloat(getTotalHlxStaked(getUserEndedStakes)).toLocaleString("en-US", {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}
                                                </td>
                                                <td>
                                                    {parseFloat(getTotalHlxShares(getUserEndedStakes)).toLocaleString("en-US", {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Content;
