import React, { Component } from "react";
import StatsContentStyleWrapper from "./StatsContent.style";
import StatesText from "../../common/statstext/StatsText";
import MiningStakingText from "../../common/miningstakingtext/MiningStakingText";
import heliosAvatar from "../../assets/helius/HLX.png";
import tooltipText from "../../assets/helius/tooltipText.json";
import heliosContract from "../../contract/helios.json";
import janusContract from "../../contract/janus.json";
import buyandburnContract from "../../contract/buyandburn.json";
import { useReadContract, useSwitchChain, useChainId } from "wagmi";
import { useSelector } from "react-redux";

import { HeliosAddress, etherToFixed, BuyandburnAddress, JanusContractAddress } from "../../const/const";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { ethers } from "ethers";
import { pulsechain } from "viem/chains";
import { mainnet2 } from "../../const/chains";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const StatsContent = () => {
    // Start Get Redux store variables
    const hlx_to_usd = useSelector((state) => state.price.hlx_to_usd); // Fetch the value of HLX price from redux
    const jns_to_usd = useSelector((state) => state.price.jns_to_usd); // Fetch the value of Jns price from redux

    const { switchChain } = useSwitchChain();
    const chainId = useChainId();

    const rpcUrl = "https://rpc.pulsechain.com";
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

    useEffect(() => {
        if (chainId && chainId !== 1) {
            switchChain({ chainId: 1 });
        }
    }, [chainId, switchChain]);
    /**
     *@description: getGlobalHRank
     */
    const { data: globalHRank } = useReadContract({
        address: HeliosAddress,
        abi: heliosContract.abi,
        chainId: mainnet2.id,
        functionName: "getGlobalHRank",
        watch: true,
    });

    /**
     *@description: getGlobalMintPower
     */
    const { data: globalMintPower } = useReadContract({
        address: HeliosAddress,
        chainId: mainnet2.id,
        abi: heliosContract.abi,
        functionName: "getGlobalMintPower",
        watch: true,
    });

    /**
     *@description: getGlobalShares
     */
    const { data: globalShares } = useReadContract({
        address: HeliosAddress,
        chainId: mainnet2.id,
        abi: heliosContract.abi,
        functionName: "getGlobalShares",
        watch: true,
    });

    /**
     *@description: totalHeliosLiquidSupply
     */
    const { data: totalLiquidSupply } = useReadContract({
        address: BuyandburnAddress,
        chainId: mainnet2.id,
        abi: buyandburnContract.abi,
        functionName: "totalHeliosLiquidSupply",
        watch: true,
    });

    /**
     *@description: totalHeliosLiquidSupply
     */
    const { data: totalLiquidSupplyJanus } = useReadContract({
        address: JanusContractAddress,
        abi: janusContract.abi,
        functionName: "totalSupply",
        chainId: pulsechain.id,
        watch: true,
    });

    /**
     *@description: getTotalHlxStaked
     */
    const { data: totalHlxStaked } = useReadContract({
        address: HeliosAddress,
        chainId: mainnet2.id,
        abi: heliosContract.abi,
        functionName: "getTotalHlxStaked",
        watch: true,
    });

    /**
     *@description: getTotalPenalties
     */
    const { data: totalPenalties } = useReadContract({
        address: HeliosAddress,
        chainId: mainnet2.id,
        abi: heliosContract.abi,
        functionName: "getTotalPenalties",
        watch: true,
    });

    /**
     *@description: getTotalBurnTotal
     */
    const { data: totalBurnTotal } = useReadContract({
        address: HeliosAddress,
        chainId: mainnet2.id,
        abi: heliosContract.abi,
        functionName: "getTotalBurnTotal",
        watch: true,
    });

    /**
     *@description: getTotalHelioBuyAndBurn
     */
    const { data: totalHlxBuyBurn } = useReadContract({
        address: BuyandburnAddress,
        chainId: mainnet2.id,
        abi: buyandburnContract.abi,
        functionName: "getTotalHelioBuyAndBurn",
        watch: true,
    });

    /**
     *@description: getHlxFeesBuyAndBurnFunds
     */
    const { data: lpFees } = useReadContract({
        address: BuyandburnAddress,
        chainId: mainnet2.id,
        abi: buyandburnContract.abi,
        functionName: "getHlxFeesBuyAndBurnFunds",
        watch: true,
    });

    /**
     * @description: getPoolAddress
     */

    const { data: poolAddress } = useReadContract({
        address: BuyandburnAddress,
        abi: buyandburnContract.abi,
        chainId: mainnet2.id,
        functionName: "getPoolAddress",
        watch: true,
    });

    //------- ----------------
    const [getGlobalHRank, setGlobalHRank] = useState(0);
    const [getGlobalMintPower, seGlobalMintPowert] = useState(0);
    const [getGlobalShares, setGlobalShares] = useState(0);
    const [getTotalLiquidSupply, setTotalLiquidSupply] = useState(0);
    const [getTotalHlxStaked, setTotalHlxStaked] = useState(0);
    const [getTotalPenalties, setTotalPenalties] = useState(0);
    const [getTotalBurnTotal, setTotalBurnTotal] = useState(0);
    const [getTotalHlxBuyBurn, setTotalHlxBuyBurn] = useState(0);
    const [hlxTitanPoolAddress, setHlxTitanPoolAddress] = useState(0);
    const [investmentPoolAddress, setInvestmentPoolAddress] = useState(0);
    const [percentageTotal, setPercentageTotal] = useState(0);
    const [getTotalLP, setTotalLP] = useState(0);
    const [getTotalDAO, setTotalDAO] = useState(0);
    const [jnsBurned, setJnsBurned] = useState(0);

    /**
     *@description: getHeliosBalance
     */
    const { data: lpHeliosbalance } = useReadContract({
        address: HeliosAddress,
        chainId: mainnet2.id,
        abi: heliosContract.abi,
        functionName: "balanceOf",
        args: [hlxTitanPoolAddress],
        watch: true,
    });

    /**
     *@description: InvestmentPool Address
     */
    const { data: investmentAddress } = useReadContract({
        address: HeliosAddress,
        chainId: mainnet2.id,
        abi: heliosContract.abi,
        functionName: "getInvestmentAddress",
        watch: true,
    });

    /**
     *@description: Investment Pool Balance
     */
    const { data: investmentBalance } = useReadContract({
        address: HeliosAddress,
        chainId: mainnet2.id,
        abi: heliosContract.abi,
        functionName: "balanceOf",
        args: [investmentPoolAddress],
        watch: true,
    });

    const [getLpFees, setLpFees] = useState(0);
    const [getTotalSupply, setGetTotalSupply] = useState(1);
    const options = {
        exportEnabled: false,
        animationEnabled: true,
        backgroundColor: "transparent",
        height: 350,
        legend: {
            fontColor: "white", // Set legend color
        },
        data: [
            {
                type: "pie",
                startAngle: 375,
                toolTipContent: "<b>{label}</b>: {y}%",
                showInLegend: "false",
                legendText: "{label}",
                indexLabelFontSize: 16,
                indexLabelFontColor: "white",
                indexLabelColor: "white",
                indexLabel: "{label} - {y}%",
                labelFontColor: "white",
                dataPoints: [
                    {
                        y: ((getTotalLiquidSupply / percentageTotal) * 100).toFixed(2),
                        label: "Liquid",
                        color: "#EFBA1C",
                    },

                    {
                        y: ((getTotalDAO / percentageTotal) * 100).toFixed(2),
                        label: "DAO",
                        color: "#FF4D00",
                    },

                    {
                        y: ((getTotalHlxStaked / percentageTotal) * 100).toFixed(2),
                        label: "Staked",
                        color: "#BD6925",
                    },

                    {
                        y: ((getTotalPenalties / percentageTotal) * 100).toFixed(2),
                        label: "Penalties ",
                        color: "#CB012A",
                    },

                    {
                        y: ((getTotalHlxBuyBurn / percentageTotal) * 100).toFixed(2),
                        label: "Buy & Burned",
                        color: "#CB012A",
                    },

                    {
                        y: ((jnsBurned / percentageTotal) * 100).toFixed(2),
                        label: "Janus burned",
                        color: "#CB012A",
                    },

                    {
                        y: ((getTotalLP / percentageTotal) * 100).toFixed(2),
                        label: "LP",
                        color: "#FF9900",
                    },
                ],
            },
        ],
    };

    // useEffect(() => {
    //   const contract = new ethers.Contract(
    //     "0xd5A96B6ACa28302CF1B0F860673851705D25667e",
    //     janusContract.abi,
    //     provider
    //   );

    //   const getBurnedTokens = async () => {
    //     const filter = contract.filters.Transfer(
    //       null,
    //       "0x000000000000000000000000000000000000dead"
    //     );
    //     const events = await contract.queryFilter(filter, 20267702, "latest");

    //     let totalBurned = events.reduce((sum, event) => {
    //       return sum + parseFloat(ethers.utils.formatUnits(event.args.value, 18));
    //     }, 0);

    //     setJnsBurned(totalBurned ? totalBurned : 0);
    //   };

    //   getBurnedTokens();
    // }, []);

    useEffect(() => {
        if (totalLiquidSupplyJanus) {
            setJnsBurned(parseFloat(etherToFixed(totalLiquidSupplyJanus)) - 420000000000);
        }
    }, [totalLiquidSupplyJanus]);

    useEffect(() => {
        setGlobalHRank(globalHRank ? globalHRank.toString() : 0);
        seGlobalMintPowert(globalMintPower ? globalMintPower.toString() : 0);
        setGlobalShares(etherToFixed(globalShares ? globalShares.toString() : 0));
        setTotalHlxStaked(etherToFixed(totalHlxStaked ? totalHlxStaked.toString() : 0));
        setTotalPenalties(etherToFixed(totalPenalties ? totalPenalties.toString() : 0));
        setTotalBurnTotal(totalBurnTotal ? etherToFixed(totalBurnTotal.toString()) : 0);

        setTotalHlxBuyBurn(totalHlxBuyBurn ? etherToFixed(totalHlxBuyBurn.toString()) : 0);
        setLpFees(lpFees ? etherToFixed(lpFees.toString()) : 0);
        setGetTotalSupply(etherToFixed(totalLiquidSupply ? totalLiquidSupply : 0));
        setHlxTitanPoolAddress(poolAddress ? poolAddress.toString() : ethers.constants.AddressZero);
        setInvestmentPoolAddress(investmentAddress ? investmentAddress.toString() : ethers.constants.AddressZero);
        setTotalLP(lpHeliosbalance ? etherToFixed(lpHeliosbalance.toString()) : 0);
        setTotalDAO(investmentBalance ? etherToFixed(investmentBalance.toString()) : 0);
    }, [
        globalHRank,
        totalLiquidSupply,
        totalHlxStaked,
        totalPenalties,
        totalBurnTotal,
        totalHlxBuyBurn,
        lpFees,
        globalMintPower,
        globalShares,
        poolAddress,
        investmentAddress,
        investmentBalance,
        lpHeliosbalance,
    ]);

    useEffect(() => {
        const total = parseFloat(getTotalSupply) - parseFloat(getTotalLP) - parseFloat(getTotalDAO);
        setTotalLiquidSupply(total);
    }, [getTotalSupply, getTotalLP, getTotalDAO]);

    useEffect(() => {
        setPercentageTotal(
            getTotalLiquidSupply +
                getTotalBurnTotal +
                getTotalHlxBuyBurn +
                getTotalHlxStaked +
                getLpFees +
                getTotalPenalties +
                getTotalLP +
                getTotalDAO
        );
    }, [getTotalLiquidSupply, getTotalBurnTotal, getTotalHlxBuyBurn, getTotalPenalties, getTotalHlxStaked, getLpFees, getTotalLP, getTotalDAO]);

    return (
        <StatsContentStyleWrapper>
            <div className="mine_container">
                <div className="ether_subtitle">
                    <img className="helius_icon" src={heliosAvatar} alt="" />
                    <h2>The Helios Ecosystem at a Glance</h2>
                </div>
                <div className="mine_top">
                    <div className="main_tabs">
                        <div className="tablist">
                            <div className="miner_cards">
                                <h1>Helios Distribution</h1>
                            </div>
                            <CanvasJSChart options={options} />
                        </div>
                        <div className="additional_resource">
                            <h2>Additional Resources</h2>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "50%",
                                    margin: "0 auto",
                                }}
                            >
                                <a target="_blank" href="https://dune.com/mikedean/helios">
                                    Dune Analytics
                                </a>
                                <a target="_blank" href="https://www.titanxhub.com/">
                                    TitanX Hub
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="mine_details">
                        <div className="card_top">
                            <div className="top_title">
                                <h1>Supply</h1>
                            </div>
                            <div className="top_content">
                                <StatesText
                                    color={"#EFBA1C"}
                                    name="Liquid"
                                    balance={parseFloat(getTotalLiquidSupply).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={parseFloat((getTotalLiquidSupply * (hlx_to_usd !== 0 ? hlx_to_usd : 0.0)).toFixed(2)).toLocaleString(
                                        "en-US",
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                    tooltip={tooltipText.stats[0].supply.find((asset) => "liquid" in asset)?.liquid}
                                />
                                <StatesText
                                    color={"#BD6925"}
                                    name="Staked"
                                    balance={parseFloat(getTotalHlxStaked).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(getTotalHlxStaked) * parseFloat(hlx_to_usd !== 0 ? hlx_to_usd : 0.00002)).toLocaleString(
                                        "en-US",
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                    tooltip={tooltipText.stats[0].supply.find((asset) => "staked" in asset)?.staked}
                                />
                                <StatesText
                                    color={"#FF9900"}
                                    name="LP"
                                    balance={parseFloat(getTotalLP).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(getTotalLP) * parseFloat(hlx_to_usd !== 0 ? hlx_to_usd : 0.00002)).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                    tooltip={tooltipText.stats[0].supply.find((asset) => "LP" in asset)?.LP}
                                />
                                <StatesText
                                    color={"#FF4D00"}
                                    name="DAO"
                                    balance={parseFloat(getTotalDAO).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(getTotalDAO) * parseFloat(hlx_to_usd !== 0 ? hlx_to_usd : 0.00002)).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                    tooltip={tooltipText.stats[0].supply.find((asset) => "DAO" in asset)?.DAO}
                                />
                                <StatesText
                                    color="#CB012A"
                                    name="Penalties"
                                    balance={parseFloat(getTotalPenalties).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(getTotalPenalties) * (hlx_to_usd !== 0 ? hlx_to_usd : 0.00002)).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                    tooltip={tooltipText.stats[0].supply.find((asset) => "panelty" in asset)?.panelty}
                                />
                                {/* <StatesText
                  color="#CB012A"
                  name="User Burned"
                  balance={parseFloat(getTotalBurnTotal).toLocaleString(
                    "en-US",
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                  )}
                  usd={(
                    parseFloat(getTotalBurnTotal) *
                    (hlx_to_usd !== 0 ? hlx_to_usd : 0.00002)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.stats[0].supply.find(
                      (asset) => "userburned" in asset
                    )?.userburned
                  }
                /> */}
                                <StatesText
                                    color="#CB012A"
                                    name="Buy & Burned"
                                    balance={parseFloat(getTotalHlxBuyBurn).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(getTotalHlxBuyBurn) * (hlx_to_usd !== 0 ? hlx_to_usd : 0.00002)).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                    tooltip={tooltipText.stats[0].supply.find((asset) => "Buyburned" in asset)?.Buyburned}
                                />
                                {/* <StatesText
                  color="#CB012A"
                  name="LP Fees Burned"
                  balance={parseFloat(getLpFees).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(getLpFees) *
                    (hlx_to_usd !== 0 ? hlx_to_usd : 0.00002)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.stats[0].supply.find(
                      (asset) => "LPfeesburned" in asset
                    )?.LPfeesburned
                  }
                /> */}
                                <StatesText
                                    color="#CB012A"
                                    name="Janus Burned"
                                    balance={parseFloat(jnsBurned).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(jnsBurned) * (jns_to_usd !== 0 ? jns_to_usd : 0.0000000002)).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                    tooltip={tooltipText.stats[0].supply.find((asset) => "JanusBurned" in asset)?.JanusBurned}
                                />
                            </div>
                        </div>

                        <div className="card_top">
                            <div className="top_title">
                                <h1>Mining & Staking</h1>
                            </div>
                            <div className="bottom_content">
                                <MiningStakingText
                                    name="Global hRank"
                                    tooltip={tooltipText.stats[1].miningandstaking.find((asset) => "GlobalMiners" in asset)?.GlobalMiners}
                                    balance={getGlobalHRank}
                                />
                                <MiningStakingText
                                    name="Global Mining Power"
                                    tooltip={tooltipText.stats[1].miningandstaking.find((asset) => "GlobalMiningPower" in asset)?.GlobalMiningPower}
                                    balance={parseFloat(getGlobalMintPower).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                />
                                <MiningStakingText
                                    name="Global Active Shares"
                                    tooltip={tooltipText.stats[1].miningandstaking.find((asset) => "GlobalActiveShares" in asset)?.GlobalActiveShares}
                                    balance={parseFloat(getGlobalShares).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StatsContentStyleWrapper>
    );
};
export default StatsContent;
