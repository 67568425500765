export async function getCoinPrice(lpAddress, chain = "ethereum") {
    const options = { method: "GET", headers: { accept: "application/json" } };
    const result = await fetch(`https://api.dexscreener.com/latest/dex/pairs/${chain}/${lpAddress}`, options);
    if (result.status !== 200) throw Error("Failed to fetch price");
    const data = await result.json();
    if (!data.pair || !data.pair.priceUsd) {
        console.log("NO PAIR INFO FOR LP: ".lpAddress);
        console.log(data);
        return 0;
    }
    const nativePrice = data.pair.priceNative || undefined;
    return { usd: parseFloat(data.pair.priceUsd), native: nativePrice };
}

export function getTooltipText(tokenName) {
    return `This is the total amount of ${tokenName} available to be invested into new/existing Treasuries.  When and how is determined by DAO.  Head to our governance page to participate.`;
}
