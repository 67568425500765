import React, { useEffect, useState } from "react";
import TreasuryContentStyleWrapper from "./TreasuryContent.style";
import DashboardText from "../../common/dashboardtext/DashboardText";
import tooltipText from "../../assets/helius/tooltipText.json";
import heliosContract from "../../contract/helios.json";
import treasuryContract from "../../contract/treasury.json";
import titanxContract from "../../contract/titanx.json";
import wplsPool from "../../contract/wplsPool.json";
import wonderlandAbi from "../../contract/wonderLand.json";
import investAbi from "../../contract/JNSInvest.json";

import {
    useAccount,
    useReadContract,
    useReadContracts,
    useWriteContract,
    useWaitForTransactionReceipt,
    useBalance,
    useSwitchChain,
    useChainId,
} from "wagmi";
import { useSelector } from "react-redux";
import {
    TreasuryAddress,
    HeliosAddress,
    etherToFixed,
    TitanxAddress,
    InvestmentPoolAddressPls,
    BunsAddress,
    AliceAddress,
    HatterAddress,
    CheshireAddress,
    QueenAdress,
    BunsTreasuryAddress,
    BunsPlsPoolAddress,
    AlicePlsPoolAddress,
    HatterPlsPoolAddress,
    CheshirePlsPoolAddress,
    QueenPlsPoolAddress,
    Hyper,
    Dragonx,
    BabyDragonX,
    Blaze,
    Inferno,
    JakeX,
    HYDRA_ADDRESS,
    AWESOMEX_ADDRESS,
    FLUX_ADDRESS,
    VOLT_ADDRESS,
    LGNDX_ADDRESS,
    TINC_ADDRESS,
    E280_ADDRESS,
    WPLS_ADDRESS,
    InvestmentAddress,
    INVEST_POOL_SLIPPAGE,
    INVEST_POOL_DEADLINE,
} from "../../const/const";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MaxStakeTooltip from "../modal/MaxStakeTooltip";
import ClaimDistTooltip from "../modal/ClaimDistTooltip";
import { ethers } from "ethers";
import { pulsechain, mainnet } from "wagmi/chains";
import { getCoinPrice, getTooltipText } from "../../lib/getCoinPrice";
import WonderTreasuryTooltip from "../modal/WonderTreasuryTooltip";
import JNSInvestPoolTooltip from "../modal/JNSInvestPoolTooltip";
import { getBunsLimitPrice } from "../../lib/quoting";

const TreasuryContent = () => {
    const { address, isConnected } = useAccount();

    // Start Get Redux store variables
    const ether_to_usd = useSelector((state) => state.price.ether_to_usd); // Fetch the value of ether price from redux
    const titanx_to_usd = useSelector((state) => state.price.titanx_to_usd); // Fetch the value of TitanX price from redux
    const pls_to_usd = useSelector((state) => state.price.pls_to_usd);
    const hlx_to_usd = useSelector((state) => state.price.hlx_to_usd);
    const { switchChain } = useSwitchChain();
    const chainId = useChainId();
    const [liquidTitanX, setLiquidTitanX] = useState(0);
    const [titanxStaked, setTitanxStaked] = useState(0);
    const [claimableETH, setClaimableETH] = useState(0);
    const [investmentBalance, setInvestimentBalance] = useState(0);
    const [investmentPlsBalance, setInvestimentPlsBalance] = useState(0);
    const [bunsTreasuryPlsBalance, setBunsTreasuryPlsBalance] = useState(0);
    const [bunsTreasuryBunsBalance, setBunsTreasuryBunsBalance] = useState(0);
    const [bunsTreasuryAliceBalance, setBunsTreasuryAliceBalance] = useState(0);
    const [bunsTreasuryHatterBalance, setBunsTreasuryHatterBalance] = useState(0);
    const [bunsTreasuryCheshireBalance, setBunsTreasuryCheshireBalance] = useState(0);
    const [bunsTreasuryQueenBalance, setBunsTreasuryQueenBalance] = useState(0);
    const [titanXUserBalance, setTitanXUserBalance] = useState(0);
    const [hyperUserBalance, setHyperUserBalance] = useState(0);
    const [heliosUserBalance, setHeliosUserBalance] = useState(0);
    const [dragonXUserBalance, setDragonXUserBalance] = useState(0);
    const [babyDragonsXUserBalance, setBabyDragonsXUserBalance] = useState(0);
    const [blazeUserBalance, setBlazeUserBalance] = useState(0);
    const [infernoUserBalance, setInfernoUserBalance] = useState(0);
    const [jakeXUserBalance, setJakeXUserBalance] = useState(0);
    const [hydraUserBalance, setHydraUserBalance] = useState(0);
    const [awesomeXUserBalance, setAwesomeXUserBalance] = useState(0);
    const [fluxUserBalance, setFluxUserBalance] = useState(0);
    const [voltUserBalance, setVoltUserBalance] = useState(0);
    const [legendXUserBalance, setLegendXUserBalance] = useState(0);
    const [tincUserBalance, setTincUserBalance] = useState(0);
    const [e280UserBalance, setE280UserBalance] = useState(0);

    const [e280UsdtPrice, setE280UsdtPrice] = useState(0);
    const [hyperUsdtPrice, setHyperUsdtPrice] = useState(0);
    const [dragonXUsdtPrice, setDragonXUsdtPrice] = useState(0);
    const [babyDragonXUsdtPrice, setBabyDragonXUsdtPrice] = useState(0);
    const [blazeUsdtPrice, setBlazeUsdtPrice] = useState(0);
    const [infernoUsdtPrice, setInfernoUsdtPrice] = useState(0);
    const [jakeXUsdtPrice, setJakeXUsdtPrice] = useState(0);
    const [hydraUsdtPrice, setHydraUsdtPrice] = useState(0);
    const [awesomeXUsdtPrice, setAwesomeXUsdtPrice] = useState(0);
    const [fluxUsdtPrice, setFluxUsdtPrice] = useState(0);
    const [voltUsdtPrice, setVoltUsdtPrice] = useState(0);
    const [legendXUsdtPrice, setLegendXUsdtPrice] = useState(0);
    const [tincUsdtPrice, setTincUsdtPrice] = useState(0);

    const [activeHlxStakeContract, setActiveHlxStakeContract] = useState(TreasuryAddress);
    const [disableButton, setDisableButton] = useState(false);

    const [bunsUsdtPrice, setBunsUsdtPrice] = useState(0);
    const [aliceUsdtPrice, setAliceUsdtPrice] = useState(0);
    const [hatterUsdtPrice, setHatterUsdtPrice] = useState(0);
    const [cheshireUsdtPrice, setCheshireUsdtPrice] = useState(0);
    const [queenUsdtPrice, setQueenUsdtPrice] = useState(0);
    const [userEther08, setuserEther08] = useState(0);
    const [userEther28, setuserEther28] = useState(0);
    const [userEther90, setuserEther90] = useState(0);
    const [userEther369, setuserEther369] = useState(0);
    const [userEther888, setuserEther888] = useState(0);

    const heliosObj = {
        address: HeliosAddress,
        abi: heliosContract.abi,
        chainId: mainnet.id,
    };
    const treasuryObj = {
        address: TreasuryAddress,
        abi: treasuryContract.abi,
        chainId: mainnet.id,
    };
    const titanxObj = {
        address: TitanxAddress,
        abi: titanxContract.abi,
        chainId: mainnet.id,
    };

    const { data: bunsPoolData } = useReadContracts({
        contracts: [
            {
                address: BunsPlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "token0",
                chainId: pulsechain.id,
            },
            {
                address: BunsPlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "getReserves",
                chainId: pulsechain.id,
            },
        ],
    });

    const { data: alicePoolData } = useReadContracts({
        contracts: [
            {
                address: AlicePlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "token0",
                chainId: pulsechain.id,
            },
            {
                address: AlicePlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "getReserves",
                chainId: pulsechain.id,
            },
        ],
    });

    const { data: hatterPoolData } = useReadContracts({
        contracts: [
            {
                address: HatterPlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "token0",
                chainId: pulsechain.id,
            },
            {
                address: HatterPlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "getReserves",
                chainId: pulsechain.id,
            },
        ],
    });

    const { data: cheshirePoolData } = useReadContracts({
        contracts: [
            {
                address: CheshirePlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "token0",
                chainId: pulsechain.id,
            },
            {
                address: CheshirePlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "getReserves",
                chainId: pulsechain.id,
            },
        ],
    });

    const { data: queensPoolData } = useReadContracts({
        contracts: [
            {
                address: QueenPlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "token0",
                chainId: pulsechain.id,
            },
            {
                address: QueenPlsPoolAddress,
                abi: wplsPool.abi,
                functionName: "getReserves",
                chainId: pulsechain.id,
            },
        ],
    });

    useEffect(() => {
        if (bunsPoolData && bunsPoolData[0].status === "success") {
            const token0 = bunsPoolData[0].result;
            if (token0 === BunsAddress) {
                const BunsPriceInPLS = parseFloat(bunsPoolData[1].result[1].toString()) / parseFloat(bunsPoolData[1].result[0].toString());
                if (pls_to_usd !== 0) {
                    setBunsUsdtPrice((parseFloat(BunsPriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            } else {
                const BunsPriceInPLS = parseFloat(bunsPoolData[1].result[0].toString()) / parseFloat(bunsPoolData[1].result[1].toString());

                if (pls_to_usd !== 0) {
                    setBunsUsdtPrice((parseFloat(BunsPriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            }
        }
    }, [bunsPoolData, pls_to_usd]);

    useEffect(() => {
        if (alicePoolData && alicePoolData[0].status === "success") {
            const token0 = alicePoolData[0].result;
            if (token0 === AliceAddress) {
                const AlicePriceInPLS = parseFloat(alicePoolData[1].result[1].toString()) / parseFloat(alicePoolData[1].result[0].toString());
                if (pls_to_usd !== 0) {
                    setAliceUsdtPrice((parseFloat(AlicePriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            } else {
                const AlicePriceInPLS = parseFloat(alicePoolData[1].result[0].toString()) / parseFloat(alicePoolData[1].result[1].toString());

                if (pls_to_usd !== 0) {
                    setAliceUsdtPrice((parseFloat(AlicePriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            }
        }
    }, [alicePoolData, pls_to_usd]);

    useEffect(() => {
        if (hatterPoolData && hatterPoolData[0].status === "success") {
            const token0 = hatterPoolData[0].result;
            if (token0 === HatterAddress) {
                const HatterPriceInPLS = parseFloat(hatterPoolData[1].result[1].toString()) / parseFloat(hatterPoolData[1].result[0].toString());
                if (pls_to_usd !== 0) {
                    setHatterUsdtPrice((parseFloat(HatterPriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            } else {
                const HatterPriceInPLS = parseFloat(hatterPoolData[1].result[0].toString()) / parseFloat(hatterPoolData[1].result[1].toString());

                if (pls_to_usd !== 0) {
                    setHatterUsdtPrice((parseFloat(HatterPriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            }
        }
    }, [hatterPoolData, pls_to_usd]);

    useEffect(() => {
        if (cheshirePoolData && cheshirePoolData[0].status === "success") {
            const token0 = cheshirePoolData[0].result;
            if (token0 === CheshireAddress) {
                const CheshirePriceInPLS =
                    parseFloat(cheshirePoolData[1].result[1].toString()) / parseFloat(cheshirePoolData[1].result[0].toString());
                if (pls_to_usd !== 0) {
                    setCheshireUsdtPrice((parseFloat(CheshirePriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            } else {
                const CheshirePriceInPLS =
                    parseFloat(cheshirePoolData[1].result[0].toString()) / parseFloat(cheshirePoolData[1].result[1].toString());

                if (pls_to_usd !== 0) {
                    setCheshireUsdtPrice((parseFloat(CheshirePriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            }
        }
    }, [cheshirePoolData, pls_to_usd]);

    useEffect(() => {
        if (queensPoolData && queensPoolData[0].status === "success") {
            const token0 = queensPoolData[0].result;
            if (token0 === QueenAdress) {
                const QueensPriceInPLS = parseFloat(queensPoolData[1].result[1].toString()) / parseFloat(queensPoolData[1].result[0].toString());
                if (pls_to_usd !== 0) {
                    setQueenUsdtPrice((parseFloat(QueensPriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            } else {
                const QueensPriceInPLS = parseFloat(queensPoolData[1].result[0].toString()) / parseFloat(queensPoolData[1].result[1].toString());

                if (pls_to_usd !== 0) {
                    setQueenUsdtPrice((parseFloat(QueensPriceInPLS.toString()) * parseFloat(pls_to_usd)).toFixed(18));
                }
            }
        }
    }, [queensPoolData, pls_to_usd]);

    // const { data: InvestmentAddress } = useReadContract({
    //     address: TreasuryAddress,
    //     abi: treasuryContract.abi,
    //     functionName: "getInvestmentPoolAddress",
    //     chainId: mainnet.id,
    //     watch: true,
    // });

    const { data: treasuryReads } = useReadContracts({
        contracts: [
            {
                address: BunsAddress,
                abi: heliosContract.abi,
                functionName: "balanceOf",
                args: [BunsTreasuryAddress],
                chainId: pulsechain.id,
            },
            {
                address: AliceAddress,
                abi: heliosContract.abi,
                functionName: "balanceOf",
                args: [BunsTreasuryAddress],
                chainId: pulsechain.id,
            },
            {
                address: HatterAddress,
                abi: heliosContract.abi,
                functionName: "balanceOf",
                args: [BunsTreasuryAddress],
                chainId: pulsechain.id,
            },
            {
                address: CheshireAddress,
                abi: heliosContract.abi,
                functionName: "balanceOf",
                args: [BunsTreasuryAddress],
                chainId: pulsechain.id,
            },
            {
                address: QueenAdress,
                abi: heliosContract.abi,
                functionName: "balanceOf",
                args: [BunsTreasuryAddress],
                chainId: pulsechain.id,
            },
            {
                address: TitanxAddress,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },
            {
                address: HeliosAddress,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },
            {
                address: Hyper,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },
            {
                address: Dragonx,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },

            {
                address: BabyDragonX,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },
            {
                address: Blaze,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },

            {
                address: Inferno,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },

            {
                address: JakeX,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },
            {
                address: HYDRA_ADDRESS,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },
            {
                address: AWESOMEX_ADDRESS,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },
            {
                address: FLUX_ADDRESS,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },
            {
                address: VOLT_ADDRESS,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },
            {
                address: LGNDX_ADDRESS,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },
            {
                address: TINC_ADDRESS,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },
            {
                address: E280_ADDRESS,
                abi: titanxContract.abi,
                functionName: "balanceOf",
                args: [InvestmentAddress],
                chainId: mainnet.id,
            },
            {
                address: WPLS_ADDRESS,
                abi: heliosContract.abi,
                functionName: "balanceOf",
                args: [BunsTreasuryAddress],
                chainId: pulsechain.id,
            },
            {
                address: WPLS_ADDRESS,
                abi: heliosContract.abi,
                functionName: "balanceOf",
                args: [InvestmentPoolAddressPls],
                chainId: pulsechain.id,
            },
        ],
        watch: true,
    });

    useEffect(() => {
        if (treasuryReads && treasuryReads[0].status === "success") {
            setBunsTreasuryBunsBalance(treasuryReads[0] ? (treasuryReads[0]?.status === "success" ? treasuryReads[0]?.result?.toString() : 0) : 0);
            setBunsTreasuryAliceBalance(treasuryReads[1] ? (treasuryReads[1]?.status === "success" ? treasuryReads[1]?.result?.toString() : 0) : 0);

            setBunsTreasuryHatterBalance(treasuryReads[2] ? (treasuryReads[2]?.status === "success" ? treasuryReads[2]?.result?.toString() : 0) : 0);

            setBunsTreasuryCheshireBalance(
                treasuryReads[3] ? (treasuryReads[3]?.status === "success" ? treasuryReads[3]?.result?.toString() : 0) : 0
            );

            setBunsTreasuryQueenBalance(treasuryReads[4] ? (treasuryReads[4]?.status === "success" ? treasuryReads[4]?.result?.toString() : 0) : 0);
            setTitanXUserBalance(treasuryReads[5] ? (treasuryReads[5]?.status === "success" ? treasuryReads[5]?.result?.toString() : 0) : 0);
            setHeliosUserBalance(treasuryReads[6] ? (treasuryReads[6]?.status === "success" ? treasuryReads[6]?.result?.toString() : 0) : 0);
            setHyperUserBalance(treasuryReads[7] ? (treasuryReads[7]?.status === "success" ? treasuryReads[7]?.result?.toString() : 0) : 0);
            setDragonXUserBalance(treasuryReads[8] ? (treasuryReads[8]?.status === "success" ? treasuryReads[8]?.result?.toString() : 0) : 0);
            setBabyDragonsXUserBalance(treasuryReads[9] ? (treasuryReads[9]?.status === "success" ? treasuryReads[9]?.result?.toString() : 0) : 0);
            setBlazeUserBalance(treasuryReads[10] ? (treasuryReads[10]?.status === "success" ? treasuryReads[10]?.result?.toString() : 0) : 0);
            setInfernoUserBalance(treasuryReads[11] ? (treasuryReads[11]?.status === "success" ? treasuryReads[11]?.result?.toString() : 0) : 0);
            setJakeXUserBalance(treasuryReads[12] ? (treasuryReads[12]?.status === "success" ? treasuryReads[12]?.result?.toString() : 0) : 0);
            setHydraUserBalance(treasuryReads[13] ? (treasuryReads[13]?.status === "success" ? treasuryReads[13]?.result?.toString() : 0) : 0);
            setAwesomeXUserBalance(treasuryReads[14] ? (treasuryReads[14]?.status === "success" ? treasuryReads[14]?.result?.toString() : 0) : 0);
            setFluxUserBalance(treasuryReads[15] ? (treasuryReads[15]?.status === "success" ? treasuryReads[15]?.result?.toString() : 0) : 0);
            setVoltUserBalance(treasuryReads[16] ? (treasuryReads[16]?.status === "success" ? treasuryReads[16]?.result?.toString() : 0) : 0);
            setLegendXUserBalance(treasuryReads[17] ? (treasuryReads[17]?.status === "success" ? treasuryReads[17]?.result?.toString() : 0) : 0);
            setTincUserBalance(treasuryReads[18] ? (treasuryReads[18]?.status === "success" ? treasuryReads[18]?.result?.toString() : 0) : 0);
            setE280UserBalance(treasuryReads[19] ? (treasuryReads[19]?.status === "success" ? treasuryReads[19]?.result?.toString() : 0) : 0);

            getAddtionalTokensInfo();
        }
    }, [treasuryReads]);

    const { data } = useReadContracts({
        contracts: [
            {
                ...heliosObj,
                functionName: "getGlobalActiveShares",
            },
            {
                ...heliosObj,
                functionName: "getETHCyclePayoutPool",
                args: [22],
            },
            {
                ...heliosObj,
                functionName: "getETHCyclePayoutPool",
                args: [69],
            },
            {
                ...heliosObj,
                functionName: "getETHCyclePayoutPool",
                args: [420],
            },
            {
                ...heliosObj,
                functionName: "getCyclePayoutPool",
                args: [22],
            },
            {
                ...heliosObj,
                functionName: "getCyclePayoutPool",
                args: [69],
            },
            {
                ...heliosObj,
                functionName: "getCyclePayoutPool",
                args: [420],
            },
            {
                ...heliosObj,
                functionName: "calculateUserCycleReward",
                args: [address, 22],
            },
            {
                ...heliosObj,
                functionName: "calculateUserCycleReward",
                args: [address, 69],
            },
            {
                ...heliosObj,
                functionName: "calculateUserCycleReward",
                args: [address, 420],
            },
            {
                ...heliosObj,
                functionName: "getUndistributedTitanX",
            },
            {
                ...heliosObj,
                functionName: "getUndistributedETH",
            },
            {
                ...titanxObj,
                functionName: "getCyclePayoutPool",
                args: [8],
            },
            {
                ...titanxObj,
                functionName: "getCyclePayoutPool",
                args: [28],
            },
            {
                ...titanxObj,
                functionName: "getCyclePayoutPool",
                args: [90],
            },
            {
                ...titanxObj,
                functionName: "getCyclePayoutPool",
                args: [369],
            },
            {
                ...titanxObj,
                functionName: "getCyclePayoutPool",
                args: [888],
            },
        ],
        watch: true,
    });

    const { data: treasuryData } = useReadContracts({
        contracts: [
            {
                ...treasuryObj,
                functionName: "getTitanBalance",
            },
            {
                ...treasuryObj,
                functionName: "getTotalTitanStaked",
            },
            {
                ...treasuryObj,
                functionName: "getTotalTitanUnstaked",
            },
            {
                ...titanxObj,
                functionName: "getUserETHClaimableTotal",
                args: [activeHlxStakeContract],
            },
            {
                ...treasuryObj,
                functionName: "activeHlxStakeContract",
            },
        ],
        watch: true,
    });

    const { data: userEst } = useReadContracts({
        contracts: [
            {
                ...titanxObj,
                functionName: "getGlobalActiveShares",
            },
            {
                ...titanxObj,
                functionName: "getUserCurrentActiveShares",
                args: [activeHlxStakeContract],
            },
        ],
        watch: true,
    });

    /**
     *@description: Max Stake button stakeTITANX()
     */
    const { data: maxStakeData, writeContract: maxStake, error: maxStakeError, isLoading: isMaxStakeLoading } = useWriteContract();

    const maxStakeClicked = async () => {
        if (chainId !== mainnet.id) {
            toast.error("Please switch your network to ETH mainnet", {
                autoClose: 5000,
            });
            return;
        }
        maxStake({
            address: TreasuryAddress,
            abi: treasuryContract.abi,
            functionName: "stakeTITANX",
            args: [],
            chainId: mainnet.id,
        });
    };

    const { isSuccess: isMaxStakeConfirmed } = useWaitForTransactionReceipt({
        hash: maxStakeData,
    });

    useEffect(() => {
        if (maxStakeError) {
            toast.error("Minimum wait time of 7 days or minimum 100B TITANX balance required", {
                autoClose: 5000,
            });
        }
    }, [maxStakeError]);

    useEffect(() => {
        if (isMaxStakeConfirmed) {
            toast.success("Staked Successfully", {
                autoClose: 5000,
            });
        }
    }, [isMaxStakeConfirmed]);

    /**
     *@description: Claim Distribute -> claimReward()
     */
    const {
        data: claimDistributeData,
        writeContract: claimDistribute,
        error: claimDistributeError,
        isLoading: isClaimDistributeLoading,
    } = useWriteContract();

    const { isSuccess: isClaimDistributeConfirmed } = useWaitForTransactionReceipt({
        hash: claimDistributeData,
    });

    useEffect(() => {
        if (claimDistributeError) {
            toast.error("There is no ETH to claim", {
                autoClose: 5000,
            });
        }
    }, [claimDistributeError]);

    /**
     *@description: Wonderland Treasury -> distributePLS()
     */
    const {
        data: treasuryDistributeData,
        writeContract: treasuryDistribute,
        error: treasuryDistributeError,
        isLoading: isTreasuryDistributeLoading,
    } = useWriteContract();

    const { isSuccess: isTreasuryDistributeConfirmed } = useWaitForTransactionReceipt({
        hash: treasuryDistributeData,
    });

    useEffect(() => {
        if (treasuryDistributeError) {
            toast.error("No PLS Available for distribution", {
                autoClose: 5000,
            });
        }
    }, [treasuryDistributeError]);

    useEffect(() => {
        if (isTreasuryDistributeConfirmed) {
            toast.success("Treasury Distributed Successfully", {
                autoClose: 5000,
            });
        }
    }, [isTreasuryDistributeConfirmed]);

    const treasutyDistributeClicked = async () => {
        if (chainId !== pulsechain.id) {
            toast.error("Please switch your network to Pulse chain", {
                autoClose: 5000,
            });
            return;
        }
        treasuryDistribute({
            address: BunsTreasuryAddress,
            abi: wonderlandAbi.abi,
            functionName: "distributePLS",
            args: [],
        });
    };

    const { data: JNSInvestData } = useReadContracts({
        contracts: [
            {
                address: InvestmentPoolAddressPls,
                abi: investAbi.abi,
                chainId: pulsechain.id,
                functionName: "buyBurnShareBPS",
            },
            {
                address: InvestmentPoolAddressPls,
                abi: investAbi.abi,
                chainId: pulsechain.id,
                functionName: "incentiveFee",
            },
            {
                address: InvestmentPoolAddressPls,
                abi: investAbi.abi,
                chainId: pulsechain.id,
                functionName: "capPerDistribution",
            },
        ],
        watch: true,
    });

    /**
     *@description: JNSInvestmentPool -> distributePLS()
     */
    const {
        data: jnsTreasuryDistributeData,
        writeContract: jnsTreasuryDistribute,
        error: jnsTreasuryDistributeError,
        isLoading: isJnsTreasuryDistributeLoading,
    } = useWriteContract();

    const { isSuccess: isJnsTreasuryDistributeConfirmed } = useWaitForTransactionReceipt({
        hash: jnsTreasuryDistributeData,
    });

    useEffect(() => {
        if (jnsTreasuryDistributeError) {
            if (jnsTreasuryDistributeError.metaMessages[0] === "Error: Unauthorized()") {
                toast.error("You are not whitelisted", {
                    autoClose: 5000,
                });
                return;
            }
            if (jnsTreasuryDistributeError.metaMessages[0] === "Error: Cooldown()") {
                toast.error("Distribution cooldown in progress", {
                    autoClose: 5000,
                });
                return;
            }
            toast.error(jnsTreasuryDistributeError.metaMessages[0], {
                autoClose: 5000,
            });
        }
    }, [jnsTreasuryDistributeError]);

    useEffect(() => {
        if (isJnsTreasuryDistributeConfirmed) {
            toast.success("Treasury Distributed Successfully", {
                autoClose: 5000,
            });
        }
    }, [isJnsTreasuryDistributeConfirmed]);

    const jnsTreasutyDistributeClicked = async () => {
        if (chainId !== pulsechain.id) {
            toast.error("Please switch your network to Pulse chain", {
                autoClose: 5000,
            });
            return;
        }
        if (investmentPlsBalance === 0n) {
            toast.error("No PLS Available for distribution", {
                autoClose: 5000,
            });
            return;
        }
        if (!JNSInvestData || !JNSInvestData[0].status === "success") {
            toast.error("Error loading JNS data", {
                autoClose: 5000,
            });
            return;
        }
        const buyBurnShareBPS = JNSInvestData[0].result;
        const incentiveFee = JNSInvestData[1].result;
        const distributionCap = JNSInvestData[2].result;
        const distrbutionValue = investmentPlsBalance > distributionCap ? distributionCap : investmentPlsBalance;
        const userIncentive = (etherToFixed(distrbutionValue) * incentiveFee) / 10000;
        const distributionAfterIncentive = etherToFixed(distrbutionValue) - userIncentive;
        const buyBurnValue = (distributionAfterIncentive * buyBurnShareBPS) / 10000;
        const bunsValue = distributionAfterIncentive - buyBurnValue;
        const amountIn = await getBunsLimitPrice(bunsValue);
        const minAmountOut = Math.round((parseInt(amountIn.toString()) * (10000 - INVEST_POOL_SLIPPAGE)) / 10000);
        const deadline = Math.round(Date.now() / 1000 + INVEST_POOL_DEADLINE);
        jnsTreasuryDistribute({
            address: InvestmentPoolAddressPls,
            abi: investAbi.abi,
            functionName: "distributePLS",
            args: [minAmountOut, deadline],
        });
    };

    const switchToChain = async (chainId) => {
        switchChain({ chainId });
    };

    useEffect(() => {
        if (data && data[12].status === "success") {
            if (userEst && userEst[0].status === "success") {
                if (parseFloat(userEst[0]?.result || 0) !== 0) {
                    const userEstRate =
                        (userEst[1] ? userEst[1]?.result?.toString() || 0 : 0) / (userEst[0] ? userEst[0]?.result?.toString() || 0 : 0);

                    setuserEther08(etherToFixed(data[12] ? data[12]?.result || 0 : 0) * userEstRate);
                    setuserEther28(etherToFixed(data[13] ? data[13]?.result || 0 : 0) * userEstRate);
                    setuserEther90(etherToFixed(data[14] ? data[14]?.result || 0 : 0) * userEstRate);
                    setuserEther369(etherToFixed(data[15] ? data[15]?.result || 0 : 0) * userEstRate);
                    setuserEther888(etherToFixed(data[16] ? data[16]?.result || 0 : 0) * userEstRate);
                }
            }
        }
    }, [data, userEst]);

    useEffect(() => {
        if (isClaimDistributeConfirmed) {
            toast.success("Claim Distributed Successfully", {
                autoClose: 5000,
            });
        }
    }, [isClaimDistributeConfirmed]);

    const claimDistributeClicked = async () => {
        if (chainId !== mainnet.id) {
            toast.error("Please switch your network to ETH mainnet", {
                autoClose: 5000,
            });
            return;
        }
        claimDistribute({
            address: TreasuryAddress,
            abi: treasuryContract.abi,
            functionName: "claimReward",
            args: [],
        });
    };

    const { data: userBalance } = useBalance({
        address: InvestmentAddress ? InvestmentAddress : ethers.constants.AddressZero,
        chainId: mainnet.id,
    });

    const { data: userPlsBalance } = useBalance({
        address: InvestmentPoolAddressPls,
        chainId: pulsechain.id,
    });

    const { data: userBunsTreasuryPLSBalance } = useBalance({
        address: BunsTreasuryAddress,
        chainId: pulsechain.id,
    });

    useEffect(() => {
        if (data && data[1].status === "success") {
            if ((data[11] ? data[11]?.result?.toString() || 0 : 0) === 0 && (data[10] ? data[10]?.result?.toString() || 0 : 0) === 0) {
                setDisableButton(true);
            } else {
                setDisableButton(false);
            }
        }
        if (userBalance) {
            setInvestimentBalance(userBalance ? userBalance.value : 0);
        }
        if (userPlsBalance || (treasuryReads && treasuryReads[0].status === "success")) {
            let wpls = 0n;
            if (treasuryReads && treasuryReads[0].status === "success") {
                wpls = treasuryReads[21] ? (treasuryReads[21]?.status === "success" ? treasuryReads[21]?.result : 0n) : 0n;
            }
            const native = userPlsBalance ? userPlsBalance.value : 0n;
            setInvestimentPlsBalance(native + wpls);
        }
        if (userBunsTreasuryPLSBalance || (treasuryReads && treasuryReads[0].status === "success")) {
            let wpls = 0n;
            if (treasuryReads && treasuryReads[0].status === "success") {
                wpls = treasuryReads[20] ? (treasuryReads[20]?.status === "success" ? treasuryReads[20]?.result : 0n) : 0n;
            }
            const native = userBunsTreasuryPLSBalance ? userBunsTreasuryPLSBalance.value : 0n;
            setBunsTreasuryPlsBalance(native + wpls);
        }

        if (treasuryData && treasuryData[0].status === "success") {
            setLiquidTitanX(treasuryData[0] ? treasuryData[0]?.result?.toString() || 0 : 0);
            setTitanxStaked(
                treasuryData[1]
                    ? treasuryData[2]
                        ? (
                              parseFloat(etherToFixed(treasuryData[1]?.result || 0)) - parseFloat(etherToFixed(treasuryData[2]?.result || 0))
                          )?.toString()
                        : 0
                    : 0
            );
            setActiveHlxStakeContract(treasuryData[4] ? treasuryData[4]?.result?.toString() || 0 : 0);
        }
    }, [data, treasuryData, disableButton, userBalance, userBunsTreasuryPLSBalance, userPlsBalance, treasuryReads]);

    useEffect(() => {
        if (treasuryData && treasuryData[3].status === "success") {
            setClaimableETH(treasuryData[3] ? treasuryData[3]?.result?.toString() || 0 : 0);
        }
    }, [treasuryData]);

    async function getAddtionalTokensInfo() {
        const e280Price = await getCoinPrice("0x8668601064cf7a89f634cb31a97b2e0944bdd956");
        setE280UsdtPrice(e280Price.usd);
        const infernoPrice = await getCoinPrice("0x1e90b67149e688dfb95fd73acacd8adefd16d88d");
        setInfernoUsdtPrice(infernoPrice.usd);
        const hydraPrice = await getCoinPrice("0xf8f0ef9f6a12336a1e035adddbd634f3b0962f54");
        setHydraUsdtPrice(hydraPrice.usd);
        const awesomeXPrice = await getCoinPrice("0xf643d9b4826f616240b302a4cb3c073a7f30441b");
        setAwesomeXUsdtPrice(awesomeXPrice.usd);
        const fluxPrice = await getCoinPrice("0x2278012e61c0fb38dae1579bd41a87a59a5954c2");
        setFluxUsdtPrice(fluxPrice.usd);
        const voltPrice = await getCoinPrice("0x3F1A36B6C946E406f4295A89fF06a5c7d62F2fe2");
        setVoltUsdtPrice(voltPrice.usd);
        const legendXPrice = await getCoinPrice("0x6182765a907242E974F9bAF784279766D52A2b5c");
        setLegendXUsdtPrice(legendXPrice.usd);
        const tincPrice = await getCoinPrice("0x72E0de1cC2C952326738daC05baCB9E9C25422E3");
        setTincUsdtPrice(tincPrice.usd);
        const blazePrice = await getCoinPrice("0x4D3A10d4792Dd12ececc5F3034C8e264B28485d1");
        setBlazeUsdtPrice(blazePrice.usd);
        const hyperPrice = await getCoinPrice("0x14d725edB1299fF560d96f42462f0234B65B00AF");
        setHyperUsdtPrice(hyperPrice.usd);
        const jakeXPrice = await getCoinPrice("0x243F9f22D4cc14f722ec910c2ec1234DA9DD0cC1");
        setJakeXUsdtPrice(jakeXPrice.usd);
        const babyDragonXPrice = await getCoinPrice("0x142f44D41B08d4C47C48Fe15098F066e146fEaF9");
        setBabyDragonXUsdtPrice(babyDragonXPrice.usd);
        const dragonXPrice = await getCoinPrice("0x25215d9ba4403b3DA77ce50606b54577a71b7895");
        setDragonXUsdtPrice(dragonXPrice.usd);
    }

    return (
        <TreasuryContentStyleWrapper>
            <div className="rows">
                <div className="cols">
                    <div className="reward_pool_cards">
                        <div className="cards_contents">
                            <h1>Titanx Treasury </h1>

                            <div className="card_content_rewards">
                                <DashboardText
                                    token="TITANX"
                                    name="Liquid Titanx"
                                    balance={parseFloat(etherToFixed(liquidTitanX)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={parseFloat(titanx_to_usd * parseFloat(etherToFixed(liquidTitanX))).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "liquidtitanx" in asset)?.liquidtitanx}
                                />
                                <DashboardText
                                    token="TITANX"
                                    name="Titanx Staked"
                                    balance={parseFloat(titanxStaked).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={parseFloat(titanx_to_usd * parseFloat(titanxStaked)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "titanxstaked" in asset)?.titanxstaked}
                                />
                                <DashboardText
                                    token="ETH"
                                    name="Future Est. Payouts"
                                    balance={parseFloat(userEther08 + userEther28 + userEther90 + userEther369 + userEther888).toLocaleString(
                                        "en-US",
                                        {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 4,
                                        }
                                    )}
                                    usd={parseFloat(
                                        ether_to_usd * parseFloat(userEther08 + userEther28 + userEther90 + userEther369 + userEther888)
                                    ).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "estimateETHPayout" in asset)?.estimateETHPayout}
                                />
                                <DashboardText
                                    token="ETH"
                                    name="Claimable Rewards"
                                    balance={parseFloat(etherToFixed(claimableETH)).toLocaleString("en-US", {
                                        minimumFractionDigits: 4,
                                        maximumFractionDigits: 4,
                                    })}
                                    usd={parseFloat(ether_to_usd * parseFloat(etherToFixed(claimableETH))).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "claimablerewards" in asset)?.claimablerewards}
                                />
                            </div>
                            <div className="reward_pool_btn_group">
                                <MaxStakeTooltip>
                                    {chainId === mainnet.id ? (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isMaxStakeLoading)}
                                            onClick={maxStakeClicked}
                                        >
                                            MAX STAKE
                                        </button>
                                    ) : (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isMaxStakeLoading)}
                                            onClick={() => switchToChain(mainnet.id)}
                                        >
                                            SWITCH TO ETH
                                        </button>
                                    )}
                                </MaxStakeTooltip>
                                <ClaimDistTooltip claimData={claimableETH}>
                                    {chainId === mainnet.id ? (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isClaimDistributeLoading)}
                                            onClick={claimDistributeClicked}
                                        >
                                            CLAIM & DISTRIBUTE
                                        </button>
                                    ) : (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isClaimDistributeLoading)}
                                            onClick={() => switchToChain(mainnet.id)}
                                        >
                                            SWITCH TO ETH
                                        </button>
                                    )}
                                </ClaimDistTooltip>
                            </div>
                        </div>
                        <div className="cards_contents">
                            <h1>Wonderland Treasury </h1>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="QUEEN"
                                    name="Red"
                                    balance={parseFloat(etherToFixed(bunsTreasuryQueenBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(bunsTreasuryQueenBalance)) * queenUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "queenBalance" in asset)?.queenBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="CHESHIRE"
                                    name="Cat"
                                    balance={parseFloat(etherToFixed(bunsTreasuryCheshireBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(bunsTreasuryCheshireBalance)) * cheshireUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "cheshireBalance" in asset)?.cheshireBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="HATTER"
                                    name="Mad"
                                    balance={parseFloat(etherToFixed(bunsTreasuryHatterBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(bunsTreasuryHatterBalance)) * hatterUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "hatterBalance" in asset)?.hatterBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="ALICE"
                                    name="Wonderland"
                                    balance={parseFloat(etherToFixed(bunsTreasuryAliceBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(bunsTreasuryAliceBalance)) * aliceUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "aliceBalance" in asset)?.aliceBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="BUNS"
                                    name="White Rabbit"
                                    balance={parseFloat(etherToFixed(bunsTreasuryBunsBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(bunsTreasuryBunsBalance)) * bunsUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "bunsBalance" in asset)?.bunsBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="PLS"
                                    name="Pulse"
                                    balance={parseFloat(etherToFixed(bunsTreasuryPlsBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(bunsTreasuryPlsBalance)) * pls_to_usd).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "bunsPlsBalance" in asset)?.bunsPlsBalance}
                                />
                            </div>
                            <div className="reward_pool_btn_group">
                                <WonderTreasuryTooltip plsValue={bunsTreasuryPlsBalance.toString()}>
                                    {chainId === pulsechain.id ? (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isTreasuryDistributeLoading)}
                                            onClick={treasutyDistributeClicked}
                                        >
                                            DISTRIBUTE
                                        </button>
                                    ) : (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isTreasuryDistributeLoading)}
                                            onClick={() => switchToChain(pulsechain.id)}
                                        >
                                            SWITCH TO PULSE
                                        </button>
                                    )}
                                </WonderTreasuryTooltip>
                            </div>
                        </div>
                        <div className="cards_contents">
                            <h1>Janus Treasury </h1>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="PLS"
                                    name="Pulse"
                                    balance={parseFloat(etherToFixed(investmentPlsBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(investmentPlsBalance)) * pls_to_usd).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={
                                        "This is the total amount of PLS earned from JNS taxes that goes to 50% JNS Buy & Burn / 50% Buy BUNS & add to Wonderland Treasury"
                                    }
                                />
                            </div>
                            <div className="reward_pool_btn_group">
                                <JNSInvestPoolTooltip plsValue={investmentPlsBalance}>
                                    {chainId === pulsechain.id ? (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isJnsTreasuryDistributeLoading)}
                                            onClick={jnsTreasutyDistributeClicked}
                                        >
                                            BUY & DISTRIBUTE
                                        </button>
                                    ) : (
                                        <button
                                            className="distribute_button"
                                            disabled={!(isConnected && !isJnsTreasuryDistributeLoading)}
                                            onClick={() => switchToChain(pulsechain.id)}
                                        >
                                            SWITCH TO PULSE
                                        </button>
                                    )}
                                </JNSInvestPoolTooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cols">
                    <div className="reward_pool_cards">
                        <div className="cards_contents">
                            <h1>Investment Pool </h1>

                            <div className="card_content_rewards">
                                <DashboardText
                                    token="ETH"
                                    name="Ethereum"
                                    balance={parseFloat(etherToFixed(investmentBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 4,
                                        maximumFractionDigits: 4,
                                    })}
                                    usd={(parseFloat(etherToFixed(investmentBalance)) * ether_to_usd).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "balance" in asset)?.balance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="ELMNT"
                                    name="Element 280"
                                    balance={parseFloat(etherToFixed(e280UserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(e280UserBalance)) * e280UsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={getTooltipText("Element 280")}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="TITANX"
                                    name="TitanX"
                                    balance={parseFloat(etherToFixed(titanXUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(titanXUserBalance)) * titanx_to_usd).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "titanXBalance" in asset)?.titanXBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="HYPER"
                                    name="Hyper"
                                    balance={parseFloat(etherToFixed(hyperUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(hyperUserBalance)) * hyperUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "hyperBalance" in asset)?.hyperBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="HLX"
                                    name="Helios"
                                    balance={parseFloat(etherToFixed(heliosUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(heliosUserBalance)) * hlx_to_usd).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "hlxBalance" in asset)?.hlxBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="DRAGONX"
                                    name="DragonX"
                                    balance={parseFloat(etherToFixed(dragonXUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(dragonXUserBalance)) * dragonXUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "dragonXBalance" in asset)?.dragonXBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="BDX"
                                    name="Baby DragonX"
                                    balance={parseFloat(etherToFixed(babyDragonsXUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(babyDragonsXUserBalance)) * babyDragonXUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "babyDragonXBalance" in asset)?.babyDragonXBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="HYDRA"
                                    name="Hydra"
                                    balance={parseFloat(etherToFixed(hydraUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(hydraUserBalance)) * hydraUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={getTooltipText("Hydra")}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="AWX"
                                    name="AwesomeX"
                                    balance={parseFloat(etherToFixed(awesomeXUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(awesomeXUserBalance)) * awesomeXUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={getTooltipText("AwesomeX")}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="BLAZE"
                                    name="Blaze"
                                    balance={parseFloat(etherToFixed(blazeUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(blazeUserBalance)) * blazeUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "blazeBalance" in asset)?.blazeBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="INF"
                                    name="Inferno"
                                    balance={parseFloat(etherToFixed(infernoUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(infernoUserBalance)) * infernoUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "infernoBalance" in asset)?.infernoBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="FLUX"
                                    name="Flux"
                                    balance={parseFloat(etherToFixed(fluxUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(fluxUserBalance)) * fluxUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={getTooltipText("Flux")}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="VOLT"
                                    name="Volt"
                                    balance={parseFloat(etherToFixed(voltUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(voltUserBalance)) * voltUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={getTooltipText("Volt")}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="JAKEX"
                                    name="JakeX"
                                    balance={parseFloat(etherToFixed(jakeXUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(jakeXUserBalance)) * jakeXUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={tooltipText.rewardpools[1].rightbox.find((asset) => "jakeXBalance" in asset)?.jakeXBalance}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="LGNDX"
                                    name="LegendX"
                                    balance={parseFloat(etherToFixed(legendXUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(legendXUserBalance)) * legendXUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={getTooltipText("LegendX")}
                                />
                            </div>
                            <div className="card_content_rewards">
                                <DashboardText
                                    token="TINC"
                                    name="Titan Farms"
                                    balance={parseFloat(etherToFixed(tincUserBalance)).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    usd={(parseFloat(etherToFixed(tincUserBalance)) * tincUsdtPrice).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                    tooltip={getTooltipText("Titan Farms")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TreasuryContentStyleWrapper>
    );
};

export default TreasuryContent;
