import { BunsAddress, PULSEX_ROUTER, WPLS_ADDRESS } from "../const/const";
import RouterAbi from "../contract/pulseXrouter.json";
import { ethers } from "ethers";
import { pulsechain } from "viem/chains";

// export async function getBunsLimitPrice(amountIn) {
//     const amountOut = await readContract({
//         address: PULSEX_ROUTER,
//         abi: RouterAbi,
//         functionName: "getAmountsOut",
//         args: [amountIn, [WPLS_ADDRESS, BunsAddress]],
//     });

//     // Extract the second element as amountOut
//     return amountOut[1];
// }

export async function getBunsLimitPrice(amountIn) {
    const provider = new ethers.providers.JsonRpcProvider("https://rpc.pulsechain.com", { chainId: 369 });

    const routerV2 = new ethers.Contract(PULSEX_ROUTER, RouterAbi, provider);
    const [, amountOut] = await routerV2.getAmountsOut(amountIn, [WPLS_ADDRESS, BunsAddress]);

    return amountOut;
}
