import React, { useEffect, useState } from "react";
import { MdNotes } from "react-icons/md";
import NavWrapper from "./Header.style";
import MobileMenu from "../mobileMenu/MobileMenu";
import HeaderName from "./HeaderName";
import logo from "../../../../assets/helius/2.png";
import { useLocation } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt } from "wagmi";
import { HeliosAddress } from "../../../../const/const";
import heliosContract from "../../../../contract/helios.json";
import { toast } from "react-toastify";
import ContractDayTooltip from "../../../../common/tooltip/ContractDayTooltip";
import tooltipText from "../../../../assets/helius/tooltipText.json";
import { mainnet2 } from "../../../../const/chains";

const headers = [
    { headerName: "Dashboard", to: "/" },
    { headerName: "Mine", to: "mine" },
    { headerName: "Stake", to: "stake" },
    { headerName: "Reward Pools", to: "rewardpools" },
    { headerName: "Treasury", to: "treasury" },
    { headerName: "Burn Pools", to: "burnpools" },
    { headerName: "Stats", to: "stats" },
    { headerName: "Calculator", to: "calculator" },
    { headerName: "Janus", to: "janus" },
];

const Header = () => {
    const location = useLocation();
    const [isMobileMenu, setMobileMenu] = useState(false);
    const [selectedHeader, setSelectedHeader] = useState("Dashboard");

    const handleMobileMenu = () => {
        setMobileMenu(!isMobileMenu);
    };

    /**
     * Contract Interaction Hooks
     */
    const { data: currentDay } = useReadContract({
        address: HeliosAddress,
        abi: heliosContract.abi,
        chainId: mainnet2.id,
        functionName: "getCurrentContractDay",
        watch: true,
    });

    const { data: currentBlockTimeStamp } = useReadContract({
        address: HeliosAddress,
        abi: heliosContract.abi,
        chainId: mainnet2.id,
        functionName: "getCurrentBlockTimeStamp",
        watch: true,
    });

    const { data: genesisTs } = useReadContract({
        address: HeliosAddress,
        abi: heliosContract.abi,
        chainId: mainnet2.id,
        functionName: "genesisTs",
        watch: true,
    });

    const { data: manualUpdateData, writeContract: manualUpdate, error: manualUpdateError } = useWriteContract();

    const manualUpdateClicked = async () => {
        manualUpdate({
            address: HeliosAddress,
            abi: heliosContract.abi,
            chainId: mainnet2.id,
            functionName: "manualDailyUpdate",
            args: [],
        });
    };

    const { isSuccess: isConfirmed } = useWaitForTransactionReceipt({
        hash: manualUpdateData,
    });

    useEffect(() => {
        if (manualUpdateError) {
            toast.error(manualUpdateError.message.slice(0, 50) + "...", {
                autoClose: 5000,
            });
        }
    }, [manualUpdateError]);

    useEffect(() => {
        if (isConfirmed) {
            toast.success("Manual Update Successful", {
                autoClose: 5000,
            });
        }
    }, [isConfirmed]);

    const [currentContractDay, setCurrentContractDay] = useState(0);
    const [nextContractDay, setNextContractDay] = useState("");

    useEffect(() => {
        setCurrentContractDay(currentDay ? currentDay.toString() : 0);
        const cur_blocktime = currentBlockTimeStamp ? parseInt(currentBlockTimeStamp.toString()) : 0;
        const genesis_time = genesisTs ? parseInt(genesisTs.toString()) : 0;
        const calc_time = cur_blocktime - genesis_time;
        const date = new Date(calc_time * 1000); // Convert remaining seconds to date
        const hours = date.getUTCHours().toString().padStart(2, "0");
        const minutes = date.getUTCMinutes().toString().padStart(2, "0");
        const seconds = date.getUTCSeconds().toString().padStart(2, "0");
        setNextContractDay(`${hours}H:${minutes}M:${seconds}S`);
    }, [genesisTs, currentBlockTimeStamp, currentDay]);

    useEffect(() => {
        const currentPath = location.pathname;
        switch (currentPath) {
            case "/":
                setSelectedHeader("Dashboard");
                break;
            case "/mine":
                setSelectedHeader("Mine");
                break;
            case "/stake":
                setSelectedHeader("Stake");
                break;
            case "/rewardpools":
                setSelectedHeader("Reward Pools");
                break;
            case "/treasury":
                setSelectedHeader("Treasury");
                break;
            case "/burnpools":
                setSelectedHeader("Burn Pools");
                break;
            case "/stats":
                setSelectedHeader("Stats");
                break;
            case "/calculator":
                setSelectedHeader("Calculator");
                break;
            case "/janus":
                setSelectedHeader("Janus");
                break;
            default:
                setSelectedHeader("Dashboard");
                break;
        }
    }, [location.pathname]);

    return (
        <>
            <NavWrapper className="bithu_header" id="navbar">
                <div className="bithu_menu_sect">
                    <div className="bithu_menu_left_sect">
                        <div className="bithu_menu_btns">
                            <button className="menu_btn" onClick={handleMobileMenu}>
                                <MdNotes />
                            </button>
                        </div>
                        <div className="logo">
                            <a href="https://helios-hlx.win">
                                <img src={logo} alt="bithu nft logo" />
                            </a>
                        </div>
                        <div className="bithu_menu_right_sect">
                            <div className="bithu_menu_list">
                                <ul>
                                    {headers.map((header, index) => (
                                        <HeaderName
                                            key={index}
                                            selectedHeader={selectedHeader}
                                            setSelectedHeader={setSelectedHeader}
                                            headerName={header.headerName}
                                            to={header.to}
                                        />
                                    ))}
                                    <li>
                                        <a href="https://snapshot.org/#/helios-hlx.eth" target="_blank" rel="noopener noreferrer">
                                            Governance
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://docs.helios-hlx.win/helios/" target="_blank" rel="noopener noreferrer">
                                            Docs
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="connect_button">
                        <ContractDayTooltip text={tooltipText.header}>
                            <div className="header_pill_dropdown" onClick={manualUpdateClicked}>
                                <p className="pill_day">Day {currentContractDay}</p>
                                <p className="pill_hour_time">{nextContractDay}</p>
                            </div>
                        </ContractDayTooltip>
                        <ConnectButton
                            label="Connect Wallet"
                            chainStatus="none" // icon, name, none
                            showBalance={false} // true, false
                            accountStatus="address" // avatar, address
                            className="connect_btn"
                        />
                    </div>
                </div>
                {/* <!-- Main Menu END --> */}
            </NavWrapper>
            {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
        </>
    );
};

export default Header;
