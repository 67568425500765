import "./ClaimDistToolTip.css";
import wonderlandAbi from "../../contract/wonderLand.json";
import { useContractRead } from "wagmi";
import { useSelector } from "react-redux";
import { BunsTreasuryAddress, etherToFixed } from "../../const/const";
import { useEffect, useState } from "react";
import { pulsechain } from "viem/chains";

const WonderTreasuryTooltip = (props) => {
    const { children, plsValue } = props;
    const pls_to_usd = useSelector((state) => state.price.pls_to_usd);

    const [PLS, setPLS] = useState(0);
    const [PLSUsd, setPLSUsd] = useState(0);
    const [user, setUser] = useState(0);
    const [userUsd, setUserusd] = useState(0);

    const { data: incentiveFee } = useContractRead({
        address: BunsTreasuryAddress,
        abi: wonderlandAbi.abi,
        functionName: "incentiveFee",
        chainId: pulsechain.id,
        watch: true,
    });

    useEffect(() => {
        setPLSUsd(etherToFixed(plsValue * pls_to_usd).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setPLS(etherToFixed(plsValue).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setUser(
            (etherToFixed(plsValue) * (incentiveFee ? incentiveFee / 10000 : 0.001)).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
        );
        setUserusd(
            (etherToFixed(plsValue) * (incentiveFee ? incentiveFee / 10000 : 0.001) * pls_to_usd).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
        );
    }, [plsValue, incentiveFee]);
    return (
        <div className="claimdisttooltip">
            {children}
            <div className="claimdisttooltip_content">
                <header className="modal_header">
                    <div className="header_top">Send PLS to JNS Buy & Burn</div>
                </header>
                <main className="modal_content">
                    <div className="content_container">
                        <div className="counter_box">
                            <div className="token_counter">
                                <div className="token_counter_name">
                                    <span>To Be Distributed</span>
                                </div>
                                <div className="token_value">{PLS} PLS</div>
                            </div>
                            <div className="dollar_counter">≈${PLSUsd}</div>
                        </div>
                        <div className="counter_box">
                            <div className="token_counter">
                                <div className="token_counter_name">
                                    <span>User Reward</span>
                                </div>
                                <div className="token_value">{user} PLS</div>
                            </div>
                            <div className="dollar_counter">≈${userUsd}</div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default WonderTreasuryTooltip;
